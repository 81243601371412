import { PropTypes } from "aem/ComponentWrapper";

/* scm */
export default {
  title: "Form Container",
  type: "rh/components/form/container",
  isContainer: true,
  config: {
    emptyLabel: "Form Container",
    isEmpty: (_: PropTypes) => false
  }
};
