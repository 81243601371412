import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AEMWrapper from "aem/ComponentWrapper";
import React, { FC } from "react";
import type { ComponentProps } from "react";

import { BREAKPOINT_MD, BREAKPOINT_SM } from "utils/constants";
import meta from "./aem-meta";

import RhSimpleImage from ".";

const srcSetWidths = [
  200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600
];

const buildSrcSet = url => {
  const hasQueryParams = url?.includes("?");
  return srcSetWidths
    .map(w => {
      return `${url}${hasQueryParams ? "&" : "?"}wid=${w} ${w}w`;
    })
    .join(", ");
};

export type AEMImageProps = Pick<
  ComponentProps<"img">,
  "src" | "alt" | "style" | "loading"
> & {
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  enableSmartImaging?: boolean;
  imageDMURL?: string;
  s7Preset?: string;
  imageModifiers?: string;
  loadImageFromDm?: boolean;
  zoomOnHover?: boolean;
  imgSize?: string;
  aspectRatio?: string;
};

const useStyles = ({
  hideOnMobile,
  hideOnDesktop,
  hideOnTablet
}: AEMImageProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          display: hideOnMobile ? "none !important" : undefined
        },
        [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          display: hideOnTablet ? "none !important" : undefined
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: hideOnDesktop ? "none !important" : undefined
        }
      }
    })
  );

export const AEMImage: FC<AEMImageProps> = props => {
  const { root } = useStyles(props)();
  const { imgSize, loading, aspectRatio } = props;
  let { src } = props;
  if (props.loadImageFromDm) {
    const searchParams = [];
    if (props.s7Preset) {
      searchParams.push(`$${props.s7Preset}$`);
    }

    if (props.imageModifiers) {
      searchParams.push(props.imageModifiers);
    }
    if (props.enableSmartImaging) {
      searchParams.push(`bfc=on`);
    }

    src = props.imageDMURL
      ? props?.imageDMURL && searchParams.length
        ? `${props?.imageDMURL}?${searchParams?.join("&")}`
        : props?.imageDMURL
      : props?.src;
  }
  return (
    <RhSimpleImage
      src={src}
      srcSet={buildSrcSet(src)}
      sizes={imgSize ? `${imgSize}vw` : "100vw"}
      alt={props.alt}
      style={{
        ...props.style,
        aspectRatio: aspectRatio ? aspectRatio : "auto"
      }}
      loading={loading}
      classes={root}
    />
  );
};

export default AEMWrapper(meta, AEMImage);
