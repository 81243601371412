import { ImageListProps } from "@mui/material";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@RHCommerceDev/component-global-carousel";
import { processEnvServer } from "hooks/useSsrHooks";
import RHImage from "@RHCommerceDev/rh-image-component";
import classNames from "classnames";
import NextArrow from "@RHCommerceDev/component-rh-carousel/NextArrow";
import PreviousArrow from "@RHCommerceDev/component-rh-carousel/PreviousArrow";
import PlayIcon from "icons/PlayIcon";
import React, { Dispatch, FC, SetStateAction } from "react";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export interface ImageThumbnailListProps
  extends Omit<ImageListProps, "children"> {
  direction?: "row" | "column";
  images: (ProductAlternateImage & { id: number; name?: string })[];
  index: number | undefined;
  imageCarousels?: boolean;
  onChangeIndex?: (index) => void;
  parentBaseId?: string;
  infiniteScroll?: boolean;
  isPdp?: boolean;
  extraOperations: {
    isArrowsClicked?: Boolean;
    setIsArrowsClicked?: Dispatch<SetStateAction<Boolean>>;
  };
}

export const ImageThumbnailWrapper: FC<ImageThumbnailListProps> = ({
  images,
  index,
  onChangeIndex,
  extraOperations
}) => {
  return (
    <div
      id={"component-image-thumbnail-list"}
      key={"component-image-thumbnail-list"}
      data-testid={"component-image-thumbnail-list"}
      className={`w-full`}
    >
      <Carousel
        slideToShow={5}
        scrollToSlide={index}
        carouselLength={images?.length}
        opts={{
          align: "start",
          dragFree: true
        }}
      >
        <CarouselContent>
          {images.map((subItem, idx) => (
            <CarouselItem
              key={idx}
              className="md:basis-1/5 lg:basis-1/5"
              data-testid="carousel-thumbnail-image"
            >
              <div
                className={classNames(
                  "relative box-border overflow-auto overflow-y-hidden",
                  "m-[0.8rem] h-auto",
                  {
                    "border-white border-[3px]": index === subItem?.id,
                    "outline outline-[1px] outline-black": index === subItem?.id
                  }
                )}
                id="thumbnail-wrapper"
              >
                <RHImage
                  src={subItem.imageUrl || ""}
                  alt={subItem.name || ""}
                  className={`h-full !object-contain cursor-pointer bg-transparent`}
                  style={{
                    aspectRatio: `${IMAGE_ASPECT_RATIO["thumbnail"]}`
                  }}
                  onClick={() => {
                    extraOperations.setIsArrowsClicked?.(false);
                    onChangeIndex?.(subItem?.id);
                  }}
                  data-testid="rh-image"
                />
                {Boolean(subItem?.video) && (
                  <PlayIcon className="absolute left-0 right-0 top-0 bottom-0 m-auto text-white pointer-events-none !text-[40px]" />
                )}
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        {images?.length > 5 &&
          (!processEnvServer ? (
            <CarouselPrevious>
              <PreviousArrow
                icon={"yes"}
                prevArrowAccessibilityProps={{
                  style: {
                    position: "absolute"
                  }
                }}
                carouselArrowGridStyles={{
                  left: "20px"
                }}
              />
            </CarouselPrevious>
          ) : (
            <PreviousArrow
              icon={"yes"}
              prevArrowAccessibilityProps={{
                style: {
                  position: "absolute",
                  "--tw-translate-y": "-70%"
                }
              }}
              carouselArrowGridStyles={{
                left: "-20px"
              }}
            />
          ))}
        {images?.length > 5 &&
          (!processEnvServer ? (
            <CarouselNext>
              <NextArrow
                nextArrowAccessibilityProps={{
                  style: {
                    position: "absolute",
                    "--tw-translate-y": "-70%"
                  }
                }}
                carouselArrowGridStyles={{
                  right: "20px"
                }}
                icon={"yes"}
              />
            </CarouselNext>
          ) : (
            <NextArrow
              nextArrowAccessibilityProps={{
                style: {
                  position: "absolute"
                }
              }}
              carouselArrowGridStyles={{
                right: "-20px"
              }}
              icon={"yes"}
            />
          ))}
      </Carousel>
    </div>
  );
};

ImageThumbnailWrapper.defaultProps = {};

export default ImageThumbnailWrapper;
