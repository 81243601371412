import React, { useCallback, useMemo } from "react";
import usePagination from "@mui/material/usePagination";
import { MoreHoriz } from "@mui/icons-material";
import { range } from "lodash";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import memoize from "utils/memoize";
import { usePageContent } from "customProviders/LocationProvider";
import { useFetchModel } from "hooks/useFetchModel";
import useParams from "hooks/useParams";
import useStyles from "./useStyles";
import { useEnv } from "hooks/useEnv";
import { processEnvServer as isServer } from "hooks/useSsrHooks";
import { ITEMS_PER_PAGE_PREFERENCE } from "utils/constants";
import { useLocalization } from "hooks/useLocalization";
import RHRArrowIcon from "icon-arrow-rhr";
import classNames from "classnames";
import yn from "yn";
import { getPGDefaultItemsPerPage } from "@RHCommerceDev/utils/getPGDefaultItemsPerPage";

const RHPagination = props => {
  const env = useEnv();
  const start = props.start || "no";
  const count = props.count || "maxnrpp";
  const defaultCount = props.defaultCount || String(getPGDefaultItemsPerPage());
  const itemsPerPagePreference =
    props.itemsPerPagePreference || ITEMS_PER_PAGE_PREFERENCE;

  const classes = useStyles();
  const { pathname, search } = useLocation();
  const prefix = useLocalization();
  const isAemPage = !pathname?.includes(".jsp");
  const pageContent =
    props.pageContent ||
    (!isAemPage
      ? usePageContent().pageContent
      : useFetchModel(`${prefix}/admin/products`, false, false).pageContent);

  const history = useHistory();
  const FEATURE_TAILWIND_ICON = yn(env.FEATURE_TAILWIND_ICON);
  const { totalNumRecs, recsPerPage, lastRecNum, loadMoreData } = props;

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(itemsPerPagePreference)
    : undefined;

  const params = useParams({
    [start]: "0",
    [count]: storedItemsPerPagePreference ?? defaultCount,
    site: "",
    activeTab: ""
  });

  let lastRecordNumber = +params[start] + +Number(params[count]);
  const currentPage = useMemo(() => {
    let cPage = Math.ceil(lastRecordNumber / +Number(params[count]));
    let maxPaginatedPage = Math.ceil(totalNumRecs / +Number(params[count]));
    return Number((cPage > maxPaginatedPage ? maxPaginatedPage : cPage) || 1);
  }, [lastRecNum, recsPerPage, lastRecordNumber]);

  const { items } = usePagination({
    count: Math.ceil(totalNumRecs / recsPerPage),
    page: currentPage,
    onChange: (event, value) => handleClick(event, value)
  });
  const initialActiveTab = props.activeTab;

  const handleClick = useCallback(
    (event, page, type = "") => {
      window?.scrollTo?.(0, 0);
      const queryParams = new URLSearchParams(search);
      queryParams.set(
        start,
        (+Number(params?.[count]) * (page - 1))?.toString()
      );
      if (yn(env.FEATURE_CONCEPT_TABS)) {
        !params?.site &&
          initialActiveTab?.site &&
          queryParams.set("site", initialActiveTab.site);
        !params?.activeTab &&
          initialActiveTab?.tabNo &&
          queryParams.set("activeTab", initialActiveTab.tabNo);
      }

      history?.push({ pathname, search: queryParams.toString() });
      // loadMoreData(
      //   +Number(params?.[count]),
      //   +Number(params?.[count]) * (page - 1)
      // );
      const target = event.target as HTMLElement;
      const id = (target?.parentNode as HTMLElement)?.id;
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isPaginationClick: true,
              pageNo: page,
              pageType: `${type ? type : "number"} click`,
              class: target?.className,
              id: target?.id || id
            }
          }
        })
      );
    },
    [search]
  );

  const tablet = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  return (
    <nav data-testid={"nav-wrapper"}>
      <div
        className={`flex justify-center items-center w-full mb-10 ${
          tablet ? classes.MobileListWrapper : ""
        }`}
        data-testid={"list-wrapper"}
      >
        <ul
          className={classNames([
            classes.List,
            "list-none p-0 !m-0 flex justify-center items-center gap-7 leading-[30px]"
          ])}
          data-testid={"list"}
        >
          {items.map(({ page, type, selected, ...item }, index) => {
            let children: any = null;
            if (tablet && page === currentPage) {
              children = (
                <div data-testid={"current-page"}>
                  {currentPage} of {Math.ceil(totalNumRecs / recsPerPage)}
                </div>
              );
            } else if (
              !tablet &&
              (type === "start-ellipsis" || type === "end-ellipsis")
            ) {
              children = (
                <div
                  className={classNames([
                    classes.ItemWrapper,
                    "bg-transparent relative w-[21px] h-[21px] flex justify-center items-center"
                  ])}
                  data-testid={"item-wrapper"}
                >
                  <span className={classes.ellipses} data-testid={"ellipse"}>
                    <MoreHoriz />
                  </span>
                  <div
                    data-testid={"DropdownCustom"}
                    id="dropdownCustom"
                    className={classNames([
                      classes.dropdownCustom,
                      "bg-[#f1f0ed] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] p-[25px] absolute top-[34px] transform w-[158px] cursor-pointer hidden transition-all duration-[0.5s] ease-in-out z-[1]"
                    ])}
                  >
                    <div
                      className={
                        "grid grid-cols-[repeat(auto-fit,minmax(24px,1fr))] auto-rows-[30px] gap-x-[18px] gap-y-[26px]"
                      }
                      data-testid={"page-count-wrapper"}
                    >
                      {range(
                        (items[index - 1]?.page || 0) + 1,
                        items[index + 1]?.page || 0
                      )
                        .slice(0, 9)
                        .map(i => (
                          <div
                            className={
                              "w-full flex items-center justify-center"
                            }
                            style={{
                              fontWeight: selected ? "bold" : undefined,
                              textDecoration: selected ? "underline" : ""
                            }}
                            {...item}
                            onClick={e => {
                              handleClick(e, i || 1);
                            }}
                            data-testid={"page-count"}
                          >
                            {i}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            } else if (!tablet && type === "page") {
              children = (
                <button
                  type="button"
                  className={classNames([
                    "text-[13px] !font-normal !leading-[150%] text-black tracking-[0.015em]"
                  ])}
                  style={{
                    textDecoration: selected ? "underline" : "",
                    fontFamily: selected ? "RHSans" : "RHSans-Light"
                  }}
                  {...item}
                  onClick={e => {
                    handleClick(e, page || 1);
                  }}
                  data-testid={"page-button"}
                >
                  {page}
                </button>
              );
            } else if (["previous", "next"].includes(type)) {
              children = (
                <button
                  type="button"
                  {...item}
                  className={
                    "text-black flex justify-center disabled:text-[#101010] disabled:opacity-30"
                  }
                  onClick={e => {
                    handleClick(e, page, type);
                  }}
                  data-testid={"pagination-text-wrap"}
                >
                  {type === "previous" ? (
                    <>
                      <RHRArrowIcon
                        ispdpv2={true}
                        strokeColor="black"
                        className={"my-[6px] mx-1"}
                        style={{
                          transform: FEATURE_TAILWIND_ICON
                            ? "rotate(180deg)"
                            : "rotate(90deg)"
                        }}
                      />
                      <Typography
                        className={
                          "!mr-8 !text-inherit !text-[13px] !font-light not-italic !leading-[150%] !tracking-[0.015em] !font-primary-rhlight"
                        }
                        data-testid={"pagination-previous-text"}
                      >
                        {pageContent?.PREVIOUS}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        className={
                          "!ml-8 !text-inherit !text-[13px] !font-light not-italic !leading-[150%] !tracking-[0.015em] !font-primary-rhlight"
                        }
                        data-testid={"pagination-next-text"}
                      >
                        {pageContent?.NEXT}
                      </Typography>
                      <RHRArrowIcon
                        ispdpv2={true}
                        strokeColor="black"
                        className={"my-[6px] mx-1"}
                        style={{
                          transform: FEATURE_TAILWIND_ICON
                            ? "rotate(0deg)"
                            : "rotate(270deg)"
                        }}
                      />
                    </>
                  )}
                </button>
              );
            }
            return children ? (
              <li key={index} data-testid={"page-list"}>
                {children}
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </nav>
  );
};

RHPagination.defaultProps = {
  start: "no",
  count: "maxnrpp",
  itemsPerPagePreference: ITEMS_PER_PAGE_PREFERENCE,
  pageContent: null
};

export default memoize(RHPagination);
