import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { BREAKPOINT_SM } from "utils/constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applyButton: {
      marginTop: "28px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginTop: "30px"
      }
    },
    rhrTextDecoration: {
      color: "#000000"
    },
    shippingSelectionWrapper: {
      backgroundColor:
        theme.palette.background.default || theme.palette.common.white
    },
    shippingSelectionGlobalWrapper: {
      backgroundColor:
        theme.palette.background.default || theme.palette.common.white
    },

    divider: { margin: theme.spacing(1.6, 0, 1.6, 0) },

    shippingSelectionLabel: {
      fontSize: "11px",
      color: "#000",
      fontWeight: 300,
      textTransform: "capitalize"
    },
    shippingUnits: { textTransform: "lowercase" },
    mobileLabel: { textTransform: "uppercase" },
    radioItem: {
      marginTop: theme.spacing(2.5),
      "&:first-child": {
        marginTop: theme.spacing(3)
      }
    },
    mobileSelectorTitle: {
      fontWeight: 400,
      fontSize: "15px"
    },
    mobileSelectorLabel: {
      fontSize: "11px"
    },
    saveBtn: {
      fontWeight: "bolder"
    },
    SelectorTitle: {
      fontWeight: 500
    },
    selectedShippingTitle: {
      fontWeight: 300
    }
  })
);
