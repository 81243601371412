import React, { FC } from "react";
import RHSwitch from "component-rh-switch";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Button, Divider, Grid, Typography } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_LG,
  FONT_BARON_SANS_ULTRA_THIN,
  FONT_THIN,
  FONT_BARON_SANS,
  COOKIES_URL
} from "utils/constants";
import memoize from "utils/memoize";
import RHLink from "component-rh-link";
import { useCookiePermission } from "@RHCommerceDev/hooks/useCookiePermission";
import RHMenuDrawer from "component-rh-menu-drawer";
import Cell from "component-cell";
import { useLocalization } from "hooks/useLocalization";
import { useFetchModel } from "hooks/useFetchModel";

interface CookieSelectionModalProps {
  setCookieSelectionModal: (isClosed: boolean) => void;
  open?: boolean;
  style?: any;
  onClose?: any;
  languageTogglePageContent?: {
    [key: string]: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titlePadding: {
      "&&": {
        padding: theme.spacing(2, 4, 3, 5),
        [theme.breakpoints.down(BREAKPOINT_LG)]: {
          padding: theme.spacing(2, 4, 3, 5)
        }
      }
    },
    modalHeader: {
      fontFamily: FONT_BARON_SANS_ULTRA_THIN,
      fontweight: FONT_THIN,
      textAlign: "center",
      fontStyle: "normal",
      lineHeight: "31px",
      fontSize: "27px",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        textAlign: "left"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        textAlign: "center"
      },
      [theme.breakpoints.down(400)]: {
        textAlign: "left"
      }
    },
    modalMainDescription: {
      fontFamily: FONT_BARON_SANS,
      fontSize: 13,
      color: "#404040",
      letterSpacing: "0.015em",
      lineHeight: "150%",
      fontWeight: 400,
      marginTop: theme.spacing(3),
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        marginTop: theme.spacing(2)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginTop: theme.spacing(3)
      },
      [theme.breakpoints.down(720)]: {
        marginTop: theme.spacing(2)
      }
    },
    shareInfo: {
      fontFamily: FONT_BARON_SANS,
      fontSize: 13,
      color: "#000000",
      letterSpacing: "0.015em",
      fontWeight: "normal",
      lineHeight: "150%",
      textTransform: "uppercase",
      marginTop: theme.spacing(1.6),
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        marginTop: theme.spacing(3.5)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginTop: theme.spacing(2)
      }
    },
    switchWrapper: {
      margin: theme.spacing(0),
      "& .MuiSwitch-track": {
        backgroundColor: "#dddddd",
        border: "none"
      }
    },
    savePreferences: {
      height: theme.spacing(6),
      backgroundColor: "#fff",
      color: "#000",
      marginTop: theme.spacing(5),
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        marginTop: theme.spacing(2)
      },
      "&:focus, &:focus-visible, &:focus-within": {
        outline: "1px solid #000",
        outlineOffset: "2px"
      }
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      gap: theme.spacing(2),
      margin: theme.spacing(3, 0, 5),
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        flexDirection: "column",
        margin: theme.spacing(2, 0, 5)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "row",
        margin: theme.spacing(3, 0, 5)
      },
      [theme.breakpoints.down(400)]: {
        flexDirection: "column",
        margin: theme.spacing(3, 0, 5)
      }
    },
    actionAll: {
      height: theme.spacing(6),
      backgroundColor: "#000",
      color: "#fff",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#000"
      },
      "&:focus, &:focus-visible, &:focus-within": {
        outline: "1px solid #000",
        outlineOffset: "2px"
      }
    },
    alwaysOn: {
      whiteSpace: "nowrap",
      fontSize: 13,
      color: "#000",
      letterSpacing: "0.015em",
      fontWeight: "normal"
    },
    divider: {
      width: "100%",
      margin: theme.spacing(2, 0)
    },
    privacyPolicyLink: {
      color: "#404040",
      display: "inline-block",
      fontSize: 13
    },
    closeIcon: {
      ["&&"]: {
        left: "unset",
        right: "10px"
      }
    },
    closeButtonRootClassnames: {
      padding: theme.spacing(0.25),
      "&:focus, &:focus-visible, &:focus-within": {
        outline: "1px solid #000",
        outlineOffset: "2px"
      }
    },
    drawer: {
      overflow: "hidden",
      overflowY: "hidden",
      width: "533px",
      "& .MuiDialogContent-root": {
        padding: `${theme.spacing(0, 8)} !important`,
        overflow: "auto"
      },
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        width: "400px",
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0, 5, 3)} !important`
        }
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        width: "330px",
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0.625, 5, 6)} !important`
        }
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "50vw",
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0, 4, 6)} !important`
        }
      },
      [theme.breakpoints.down(720)]: {
        width: "80vw",
        "& .MuiDialogContent-root": {
          padding: `${theme.spacing(0, 4, 5)} !important`
        }
      }
    }
  })
);

export const CookieSelectionModal: FC<CookieSelectionModalProps> = ({
  setCookieSelectionModal,
  open,
  style,
  languageTogglePageContent
}) => {
  const {
    handleSavePreferences,
    handleDenyAll,
    handleAcceptAll,
    setPreferencesFunctionalityCookie,
    setThirdPartyAnalyticsCookie,
    setAnalyticsCookie,
    thirdPartyAnalyticsCookie,
    preferencesFunctionalityCookie,
    analyticsCookie
  } = useCookiePermission({
    onSaveCallback: () => setCookieSelectionModal(!open)
  });
  const prefix = useLocalization();

  const { pageContent } = useFetchModel(`/admin/cookie-modal`, false, false);

  const classes = useStyles();

  const modalContent = languageTogglePageContent ?? pageContent;
  return (
    <RHMenuDrawer
      id={"component-rh-cookie-selection-modal"}
      open={!!open}
      anchor="right"
      titlePaddingClassNames={classes.titlePadding}
      closeButtonRootClassnames={classes.closeButtonRootClassnames}
      PaperProps={{
        className: classes.drawer
      }}
      closeIconClassnames={classes.closeIcon}
      onClose={setCookieSelectionModal}
      contentStyle={{ overflowY: "scroll", ...style?.RHMenuDrawer }}
    >
      <ClickAwayListener onClickAway={() => setCookieSelectionModal(false)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.modalHeader}>
              {modalContent?.titleOne}
            </Typography>
            <Typography className={classes.modalMainDescription}>
              {modalContent?.explanationOne}
              <RHLink
                ariaLabel={modalContent?.privacyPolicy}
                underline="always"
                href={`${prefix}${COOKIES_URL}`}
                className={classes.privacyPolicyLink}
              >
                {modalContent?.privacyPolicy}
              </RHLink>
            </Typography>
          </Grid>
          <Grid item xs={12} spacing={2} className={classes.buttonGroup}>
            <Button
              variant="outlined"
              fullWidth
              className={classes.actionAll}
              onClick={handleAcceptAll}
            >
              {modalContent?.ACCEPT_ALL || ""}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              className={classes.actionAll}
              onClick={handleDenyAll}
            >
              {modalContent?.DENY_ALL || ""}
            </Button>
          </Grid>
          <Cell
            title={modalContent?.policyTitleOne}
            text={modalContent?.policyTextOne}
            rightAddons={
              <Typography variant="body1" className={classes.alwaysOn}>
                {modalContent?.alwaysOn}
              </Typography>
            }
            bottomAddons={<Divider className={classes.divider} />}
          />
          <Cell
            title={modalContent?.policyTitleTwo}
            text={modalContent?.policyTextTwo}
            rightAddons={
              <RHSwitch
                keyProp="preferencesFunctionalityCookie"
                ariaLabel={modalContent?.policyTitleTwo}
                value={preferencesFunctionalityCookie}
                classNames={classes.switchWrapper}
                setValue={preferencesFunctionalityCookieValue =>
                  setPreferencesFunctionalityCookie(
                    preferencesFunctionalityCookieValue
                  )
                }
              />
            }
            bottomAddons={<Divider className={classes.divider} />}
          />
          <Cell
            title={modalContent?.policyTitleFour}
            text={modalContent?.policyTextFour}
            rightAddons={
              <RHSwitch
                keyProp="analyticsCookie"
                ariaLabel={modalContent?.policyTitleFour}
                value={analyticsCookie}
                classNames={classes.switchWrapper}
                setValue={analyticsCookieValue =>
                  setAnalyticsCookie(analyticsCookieValue)
                }
              />
            }
            bottomAddons={<Divider className={classes.divider} />}
          />
          <Cell
            title={modalContent?.policyTitleThree}
            text={modalContent?.policyTextThree}
            rightAddons={
              <RHSwitch
                keyProp="thirdPartyAnalyticsCookie"
                ariaLabel={modalContent?.policyTitleThree}
                value={thirdPartyAnalyticsCookie}
                classNames={classes.switchWrapper}
                setValue={thirdPartyAnalyticsCookieValue =>
                  setThirdPartyAnalyticsCookie(thirdPartyAnalyticsCookieValue)
                }
              />
            }
          />
          <Button
            variant="outlined"
            fullWidth
            className={classes.savePreferences}
            onClick={handleSavePreferences}
            disabled={
              !(
                analyticsCookie ||
                thirdPartyAnalyticsCookie ||
                preferencesFunctionalityCookie
              )
            }
          >
            {modalContent?.SAVE_MY_PREFERENCES}
          </Button>
        </Grid>
      </ClickAwayListener>
    </RHMenuDrawer>
  );
};

export default memoize(CookieSelectionModal);
