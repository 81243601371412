import React, { FC, useEffect, useState } from "react";
import { DialogProps } from "@mui/material/Dialog";
import { CE_OPEN_DIALOG } from "custom-elements/events";
import { PromosDialogProps } from "dialog-promos";
import { useLazyQuery } from "@apollo/client";
import { queryPromoDisplay } from "graphql-client/queries/promo-display";
import {
  DIALOG_TRADE_REQUEST_KEY,
  DIALOG_INTERIOR_DESIGN_KEY,
  DIALOG_CUSTOM_WINDOW_KEY
} from "component-promo/constants";
import loadable, { LoadableComponent } from "@loadable/component";

const TradeRequestDialog = loadable(
  () =>
    import(
      /* webpackChunkName: "dialog-trade-request" */ "dialog-trade-request"
    ),
  {
    fallback: <></>
  }
);
const RequestConsultationDialog = loadable(
  () =>
    import(
      /* webpackChunkName: "dialog-request-consultation" */ "dialog-request-consultation"
    ),
  { fallback: <></> }
);
const PromosDialog = loadable(
  () => import(/* webpackChunkName: "dialog-promos" */ "dialog-promos"),
  {
    fallback: <></>
  }
);
const CustomWindowDialog = loadable(
  () =>
    import(
      /* webpackChunkName: "dialog-custom-window" */ "dialog-custom-window"
    ),
  {
    fallback: <></>
  }
);
interface DialogComponentMap {
  tradeRequest: LoadableComponent<DialogProps>;
  scheduleAppointment: LoadableComponent<DialogProps>;
  "window-consultation-modal": LoadableComponent<DialogProps>;
  promo: LoadableComponent<PromosDialogProps>;
}

const DialogMap: DialogComponentMap = {
  [DIALOG_TRADE_REQUEST_KEY]: TradeRequestDialog,
  [DIALOG_INTERIOR_DESIGN_KEY]: RequestConsultationDialog,
  [DIALOG_CUSTOM_WINDOW_KEY]: CustomWindowDialog,
  promo: PromosDialog
};

export const PromoDialogContainer: FC = () => {
  const [dialogType, setDialogType] = useState("");

  const [lazyQuery, { data: { promoDisplay } = {} as Query }] =
    useLazyQuery(queryPromoDisplay);

  useEffect(() => {
    document.addEventListener(CE_OPEN_DIALOG, onOpenDialog as EventListener);

    return () =>
      document.removeEventListener(
        CE_OPEN_DIALOG,
        onOpenDialog as EventListener
      );
    // TODO: Fix eslint error and remove this comment block
    // React Hook useEffect has a missing dependency: 'onOpenDialog'. Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DialogComponent = DialogMap[dialogType];
  if (!DialogComponent) {
    return null;
  }

  return (
    <>
      <DialogComponent
        open
        onClose={() => setDialogType("")}
        data={[promoDisplay]}
      />
    </>
  );

  function onOpenDialog(event: CustomEvent) {
    if (event.detail.type === "promo") {
      lazyQuery({
        variables: {
          id: event.detail.promoId
        }
      });
    }
    setDialogType(event.detail.type);
  }
};

export default PromoDialogContainer;
