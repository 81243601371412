import { Theme, useMediaQuery } from "@mui/material";
import EditableContainer, {
  EditableContainerProps
} from "aem/ui/authoring/EditableContainer";
import React, { useEffect, useState } from "react";
import yn from "yn";
import RHCarousel, { RHCarouselProps, SPEED_MULTIPLIER, defaultProps } from ".";

export type AEMCarouselProps = RHCarouselProps &
  EditableContainerProps & {
    accessibilityLabel?: string;
    autopauseDisabled?: boolean;
    autoplay?: boolean;
    collpaseSlidesInEdit?: boolean;
    delay?: number;
    fade?: boolean;
    hideFirstSlideOnMobile?: boolean;
    hideNavArrows?: boolean;
    hideMobileDots?: boolean;
    infiniteDisabled?: boolean;
    transitionSpeed?: number;
    arrowsBackgroundColor?: string;
    arrowPlacementIsOutside?: boolean;
    darkDotTheme?: boolean;
  };

const Carousel: React.FC<AEMCarouselProps> = ({
  accessibilityLabel,
  arrowsColor,
  darkDotTheme,
  arrowsBackgroundColor,
  arrowPlacementIsOutside,
  autopauseDisabled = false,
  autoplay = false,
  children,
  delay,
  fade = false,
  hideFirstSlideOnMobile = false,
  hideNavArrows = false,
  hideMobileDots = false,
  infiniteDisabled = false,
  transitionSpeed
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const [childrenArray, setChildrenArray] = useState(
    React.Children.toArray(children)
  );

  useEffect(() => {
    if (yn(hideFirstSlideOnMobile) && isMobile) {
      setChildrenArray(React.Children.toArray(children).slice(1));
    }
  }, [children, setChildrenArray, hideFirstSlideOnMobile, isMobile]);

  const autoplaySpeed = delay ? Number(delay) : delay;

  const speed = transitionSpeed
    ? Number(transitionSpeed) / SPEED_MULTIPLIER
    : undefined;

  const visibleDots = Math.min(
    childrenArray.length,
    defaultProps!.visibleDots!
  );

  return (
    <RHCarousel
      aria-label={accessibilityLabel}
      arrowsColor={arrowsColor}
      darkDotTheme={darkDotTheme}
      arrowsBackgroundColor={arrowsBackgroundColor}
      arrowPlacementIsOutside={arrowPlacementIsOutside}
      arrowsSize="large-AEM"
      arrowsVisible={yn(hideNavArrows) ? "never" : "always"}
      dotsVisible={yn(hideMobileDots) ? "never" : "always"}
      autoplay={yn(autoplay)}
      autoplaySpeed={autoplaySpeed}
      fade={yn(fade)}
      infinite={!yn(infiniteDisabled)}
      pauseOnHover={!yn(autopauseDisabled)}
      speed={speed}
      visibleDots={visibleDots}
    >
      {childrenArray as any}
    </RHCarousel>
  );
};

export const AEMCarousel: React.FC<AEMCarouselProps> = ({
  children,
  collpaseSlidesInEdit,
  isInEditor,
  ...rest
}) => {
  if (isInEditor) {
    return (
      <EditableContainer
        {...rest}
        showContentInEditMode={!yn(collpaseSlidesInEdit)}
      >
        {children}
      </EditableContainer>
    );
  }

  return <Carousel {...rest}>{children}</Carousel>;
};

export default AEMCarousel;
