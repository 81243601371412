import React, { FC, ReactNode, useEffect } from "react";
import {
  TextField,
  TextFieldProps,
  DialogContent,
  Theme,
  DialogTitle,
  Button,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Hidden } from "hooks/useSsrHooks";
import RHDropdownIcon from "icon-dropdown";
import useState from "hooks/useState";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import MenuListOptions from "./Options/MenuListOptions";
import { isMobile } from "utils/deviceDetect";
const { deepmerge } = require("@mui/utils");
import Drawer from "component-drawer";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { v4 as uuidv4 } from "uuid";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import makeStyles from "@mui/styles/makeStyles";
export interface OptionsProps {
  options?: Array<{
    key: string;
    value: string;
    display: ReactNode;
  }>;
  value: string;
  onChange?: (value: string) => void;
  loading?: boolean;
}

export type ProductOptionDropdownProps = Omit<TextFieldProps, "onChange"> & {
  variant?: "menulist";
  options?: Array<{
    key: string;
    value: string;
    display: ReactNode;
  }>;
  value: string;
  onChange?: (value: string) => void;
  displayEmpty?: boolean;
  loading?: boolean;
  hideDefaultSelect?: boolean;
  isChecked?: boolean;
  suppressAutoClear?: boolean;
};

export const useInputBaseStyles = makeStyles((theme: Theme) => ({
  root: {
    "&$disabled": {
      backgroundColor: theme.palette.common.white,
      color: "inherit"
    }
  },
  disabled: {
    color: "inherit",
    backgroundColor: theme.palette.common.white
  }
}));

export const useLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    "&$disabled": {
      opacity: 0.75
    }
  },
  outlined: {},
  shrink: {},
  disabled: {
    opacity: 0.75
  }
}));

export const useSelectStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: `${theme.typography?.body1}px`,
    "&$disabled": {
      opacity: 0.75
    }
  },
  disabled: {
    opacity: 0.75
  }
}));

export const ProductOptionDropdown: FC<ProductOptionDropdownProps> = ({
  variant = "menulist",
  options = [],
  onChange,
  displayEmpty,
  loading,
  value,
  hideDefaultSelect = false,
  isChecked = true,
  suppressAutoClear = false,
  ...rest
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const mdUp = useMediaQuery<Theme>((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const enabled = yn(env.FEATURE_REACT_DROPDOWN);
  const [selectValue, setSelectValue] = useState("");
  const [open, setOpen] = useState(false);
  const labelClasses = useLabelStyles();
  const selectClasses = useSelectStyles();
  const inputBaseClasses = useInputBaseStyles();
  const theme = useTheme();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();

  const props: TextFieldProps = deepmerge(
    {
      variant: "outlined",
      margin: "normal",
      select: true,
      InputLabelProps: {
        classes: {
          root: labelClasses.root,
          outlined: labelClasses.outlined,
          shrink: labelClasses.shrink,
          disabled: labelClasses.disabled
        }
      },
      InputProps: {
        classes: {
          root: inputBaseClasses.root,
          disabled: inputBaseClasses.disabled
        },
        style: {
          backgroundColor: theme?.palette?.background?.default
        }
      },
      SelectProps: {
        IconComponent: RHDropdownIcon,
        native: true,
        classes: {
          root: selectClasses.root,
          disabled: selectClasses.disabled
        }
      }
    },
    rest
  );

  if (enabled) {
    if (isMobile()) {
      props.SelectProps = {
        ...props.SelectProps,
        open: false
      };
    }
  }

  const Options: FC<OptionsProps> = (
    {
      menulist: MenuListOptions
    } as any
  )[variant];

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(evt.target.value);
    onChange?.(evt.target.value);
  };

  useEffect(() => {
    if (value != "") {
      setSelectValue(value);
    }
  }, [value]);

  const [reloadKey, setReloadKey] = useState(null);

  // CLEAR SELECTIONS AFTER DISELECT THE CHECKBOX
  useEffect(() => {
    // SR-3807: For lineitem addon dropdowns, we want to keep the value visible even when the checkbox is unchecked.
    // We are doing this through this prop suppressAutoClear. It's false by default, so will only affect the addon dropdown where it is used.
    if (
      (!isChecked && !suppressAutoClear) ||
      (selectValue !== "" && value === "")
    ) {
      setSelectValue("");
    }
  }, [isChecked, suppressAutoClear]);

  useEffect(() => {
    // force a re-render on client side for problematic session

    if (!reloadKey) {
      setReloadKey(uuidv4());
    }
  }, []);
  return (
    <>
      <TextField
        data-testid="product-option-dropdown"
        id={"component-product-option-dropdown"}
        {...props}
        margin="none"
        onChange={handleOnChange}
        onMouseDown={e => enabled && !mdUp && e.preventDefault()}
        select
        disabled={loading}
        value={selectValue}
        onClick={
          enabled
            ? event => {
                if (!mdUp) {
                  setOpen(true);
                }
                rest.onClick?.(event);
              }
            : rest.onClick
        }
        key={(reloadKey ? reloadKey : 123) + "productOption"}
      >
        {/* material native inputs require 1 to 1 values when being set, otherwise it will cause the label to sit on unselected value */}
        {!hideDefaultSelect && (
          <option
            key=""
            value=""
            data-option={selectValue === "" ? `selected` : ""}
          ></option>
        )}
        {[...options].map(item => (
          <option
            key={item.key}
            value={item.value}
            data-option={item.value === selectValue ? `selected` : ""}
          >
            {item.display}
          </option>
        ))}
      </TextField>
      {enabled && (
        <Hidden mdUp>
          <DrawerComponent
            anchor="bottom"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            PaperProps={{
              style: {
                width: "95%",
                maxHeight: "90%",
                margin: "auto"
              }
            }}
          >
            <DialogTitle
              component="div"
              style={{
                padding: 0
              }}
            >
              <Button
                color="primary"
                style={{
                  justifyContent: "space-between"
                }}
                onClick={() => {
                  onChange?.("");
                  setOpen(false);
                }}
                disabled={!displayEmpty || value === ""}
                fullWidth
              >
                Choose {rest.label}
              </Button>
            </DialogTitle>
            <DialogContent
              style={{
                padding: 0,
                overflowY: "auto"
              }}
              dividers
            >
              <Options
                options={options}
                value={value}
                onChange={value => {
                  onChange?.(value);
                  setOpen(false);
                }}
              />
            </DialogContent>
          </DrawerComponent>
        </Hidden>
      )}
    </>
  );
};

export default ProductOptionDropdown;
