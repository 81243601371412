import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { FC } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { RHDividerProps } from "./types";
import memoize from "utils/memoize";

/*
  A simple wrapper for our common use case where the divider has
  a margin of 2 theme spacing and a z-index of 1
*/
export const RHDivider: FC<RHDividerProps> = ({
  orientation,
  ruleVisible,
  color,
  filter = "translucent",
  variant = "fullWidth",
  marginExtraLargeDesktop,
  marginLargeDesktop,
  marginDesktop,
  marginTablet,
  marginMobile = 2,
  heightExtraLargeDesktop,
  heightLargeDesktop,
  heightDesktop,
  heightTablet,
  heightMobile,
  darkTheme,
  middleMarginLeftXLDesktop,
  middleMarginLeftLGDesktop,
  middleMarginLeftDesktop,
  middleMarginRightDesktop,
  middleMarginLeftTablet,
  middleMarginRightXLDesktop,
  middleMarginRightLGDesktop,
  middleMarginRightTablet,
  middleMarginLeftMobile,
  middleMarginRightMobile,
  ...rest
}) => {
  const theme = useTheme();
  const baseStyles = {
    root: {
      backgroundColor: "#E6E6E6",
      marginBottom: marginMobile,
      marginTop: marginMobile,
      height: heightMobile,
      opacity: filter === "translucent" ? 0.4 : 1,
      [theme.breakpoints.up("sm")]: {
        marginBottom: marginTablet,
        marginTop: marginTablet,
        height: heightTablet
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: marginDesktop,
        marginTop: marginDesktop,
        height: heightDesktop
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: marginLargeDesktop,
        marginTop: marginLargeDesktop,
        height: heightLargeDesktop
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: marginExtraLargeDesktop,
        marginTop: marginExtraLargeDesktop,
        height: heightExtraLargeDesktop
      }
    },
    vertical: {
      marginLeft: marginMobile,
      marginRight: marginMobile,
      [theme.breakpoints.up("sm")]: {
        marginLeft: marginTablet,
        marginRight: marginTablet
      },

      [theme.breakpoints.up("md")]: {
        marginLeft: marginDesktop,
        marginRight: marginDesktop
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: marginLargeDesktop,
        marginRight: marginLargeDesktop
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: marginExtraLargeDesktop,
        marginRight: marginExtraLargeDesktop
      }
    },
    middle: {
      marginLeft: middleMarginLeftMobile,
      marginRight: middleMarginRightMobile,

      [theme.breakpoints.up("sm")]: {
        marginLeft: middleMarginLeftTablet,
        marginRight: middleMarginRightTablet
      },

      [theme.breakpoints.up("md")]: {
        marginLeft: middleMarginLeftDesktop,
        marginRight: middleMarginRightDesktop
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: middleMarginLeftLGDesktop,
        marginRight: middleMarginRightLGDesktop
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: middleMarginLeftXLDesktop,
        marginRight: middleMarginRightXLDesktop
      }
    }
  };

  function getValue(value: string | number) {
    return typeof value === "number" ? theme.spacing(value) : value;
  }

  marginDesktop = getValue(marginDesktop);
  marginTablet = getValue(marginTablet);
  marginMobile = getValue(marginMobile);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      ...baseStyles,
      ...(darkTheme !== undefined && {
        root: {
          backgroundColor: darkTheme
            ? theme.palette.common.black
            : theme.palette.common.white
        }
      })
    })
  );

  const classes = useStyles();

  const style: React.CSSProperties = {
    visibility: ruleVisible ? rest.style?.visibility : "hidden",
    backgroundColor: color,
    ...rest.style
  };
  let flexItem = rest.flexItem;
  // if (heightTablet) style.sm = { height: heightTablet };
  // if (heightDesktop) style.md = { height: heightDesktop };
  // if (heightLargeDesktop) style.lg = { height: heightLargeDesktop };
  // if (heightExtraLargeDesktop) style.xl = { height: heightExtraLargeDesktop };
  if (typeof rest.flexItem !== "boolean") {
    if (orientation === "vertical") {
      rest.flexItem = true;
    }
  }

  return (
    <Divider
      id={"component-rh-divider"}
      {...rest}
      classes={rest.classes ?? classes}
      flexItem={flexItem}
      orientation={orientation}
      style={style}
      variant={variant}
      data-testid="component-rh-divider"
    />
  );
};

RHDivider.defaultProps = {
  ruleVisible: true,
  variant: "fullWidth",
  marginDesktop: 2,
  marginTablet: 2,
  marginMobile: 2,
  color: "#CCC",
  filter: "translucent"
};

export default memoize(RHDivider);
