import React, { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { MobilePromoLayerProps } from "component-promo/MobileLayers";
import { Button, Typography, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ArrowForward from "@mui/icons-material/ArrowForward";
import PromosDialog from "dialog-promos";
import RequestConsultationDialog from "dialog-request-consultation";
import CustomWindowDialog from "dialog-custom-window";
import TradeRequestDialog from "dialog-trade-request";
import useState from "hooks/useState";
import handlePromoUrl from "utils/handlePromoUrl";
import { useQuery } from "@apollo/client";
import { queryPromoDisplay } from "graphql-client/queries/promo-display";
import classNames from "classnames";
import useCTAPageLoadAction from "hooks/page-load-actions/useCTAPageLoadAction";
import useParams from "hooks/useParams";
import {
  DIALOG_TRADE_REQUEST_KEY,
  DIALOG_INTERIOR_DESIGN_KEY,
  DIALOG_CUSTOM_WINDOW_KEY
} from "component-promo/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonLayer: {
      background: "none",
      boxSizing: "border-box",
      color: "#fff",
      fontSize: theme.typography.pxToRem(12),
      width: "auto",
      borderColor: "inherit",
      "&:hover": {
        background: "#fff",
        color: "#000"
      },
      "&.black-outline": {
        borderColor: "#000",
        color: "#000",
        "&:hover": {
          background: "#000",
          color: "#fff"
        }
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2),
        minWidth: 215,
        transition: "background-color 1s ease, color 1s ease"
      }
    }
  })
);

export const ButtonLayer: FC<MobilePromoLayerProps> = ({ layer }) => {
  const history = useHistory();
  const classes = useStyles();

  const [promosDialogOpen, setPromosDialogOpen] = useState(false);
  const [consulationDialogOpen, setConsulationDialogOpen] = useState(false);
  const [tradeRequestDialogOpen, setTradeRequestDialogOpen] = useState(false);
  const [customWindowDialogOpen, setCustomWindowDialogOpen] = useState(false);
  const params = useParams({ showPromo: "" });

  const { data: { promoDisplay } = {} as Query, loading } = useQuery<Query>(
    queryPromoDisplay,
    {
      variables: {
        id: layer.dynamicText.promoId
      },
      skip: !layer.dynamicText.promoId
    }
  );

  const onEnd = useMemo(() => {
    if (
      !loading &&
      layer.dynamicText.promoId &&
      layer.dynamicText.promoId === params.showPromo
    ) {
      return () => {
        setPromosDialogOpen(false);
      };
    }
  }, [params.showPromo, layer.dynamicText.promoId, loading]);

  useEffect(() => {
    if (
      !loading &&
      layer.dynamicText.promoId &&
      layer.dynamicText.promoId === params.showPromo
    ) {
      setPromosDialogOpen(true);
    }
  }, [loading, layer.dynamicText.promoId, params.showPromo]);

  useCTAPageLoadAction(
    {
      [DIALOG_CUSTOM_WINDOW_KEY]: () => setCustomWindowDialogOpen(true),
      [DIALOG_INTERIOR_DESIGN_KEY]: () => setConsulationDialogOpen(true),
      [DIALOG_TRADE_REQUEST_KEY]: () => setTradeRequestDialogOpen(true)
    },
    layer.dynamicText.modalType,
    []
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          if (layer.modal === "true") {
            if (layer.dynamicText.modalType === DIALOG_CUSTOM_WINDOW_KEY) {
              setCustomWindowDialogOpen(true);
            } else if (
              layer.dynamicText.modalType === DIALOG_INTERIOR_DESIGN_KEY
            ) {
              setConsulationDialogOpen(true);
            } else if (
              layer.dynamicText.modalType === DIALOG_TRADE_REQUEST_KEY
            ) {
              setTradeRequestDialogOpen(true);
            } else if (promoDisplay) {
              setPromosDialogOpen(true);
            }
          } else {
            handlePromoUrl(layer.path, history);
          }
        }}
        className={classNames([classes.buttonLayer, layer.dynamicText.classes])}
        endIcon={
          (layer.dynamicText.classes || "").includes("arrow") ? (
            <ArrowForward />
          ) : null
        }
        fullWidth
      >
        <Typography
          variant="inherit"
          color="inherit"
          dangerouslySetInnerHTML={{ __html: layer.htmlText }}
        />
      </Button>
      <PromosDialog
        data={[promoDisplay]}
        open={promosDialogOpen}
        onEnd={onEnd}
        onClose={() => setPromosDialogOpen(false)}
      />
      <RequestConsultationDialog
        open={consulationDialogOpen}
        onClose={() => setConsulationDialogOpen(false)}
      />
      <CustomWindowDialog
        open={customWindowDialogOpen}
        onClose={() => setCustomWindowDialogOpen(false)}
      />
      <TradeRequestDialog
        open={tradeRequestDialogOpen}
        onClose={() => setTradeRequestDialogOpen(false)}
      />
    </>
  );
};

ButtonLayer.defaultProps = {};

export default ButtonLayer;
