import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Button, Typography, Grid } from "@mui/material";
import memoize from "utils/memoize";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_660,
  COOKIES_URL
} from "utils/constants";
import { useCookiePermission } from "@RHCommerceDev/hooks/useCookiePermission";
import useTypographyStylesByBrand from "@RHCommerceDev/hooks/useTypographyStyles";
import classNames from "classnames";
import { useLocalization } from "hooks/useLocalization";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import { countries } from "resources/countries-config.json";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import { useCookies } from "react-cookie";
import { usePageContent } from "customProviders/LocationProvider";
interface CookiePermissionBannerProps {
  openCookieSelectionModal: (isClosed: boolean) => void;
  cookieLanguageProps?: {
    onSelectLanguage?: (string) => void;
    languageTogglePageContent?: {
      [key: string]: string;
    };
    selectedLanguage?: string;
    loading?: boolean;
  };
  showBanner?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    manageButton: {
      textDecoration: "underline",
      textUnderlinePosition: "under",
      color: "#fff",
      whiteSpace: "nowrap",
      fontSize: 11,
      cursor: "pointer",

      [theme.breakpoints.down(BREAKPOINT_660)]: {
        textAlign: "center"
      }
    },
    languageSelect: {
      paddingBottom: "3px",
      fontFamily: "RHSans-ExtraLight !important",
      cursor: "pointer"
    },
    languageSelected: {
      paddingBottom: "3px",
      borderBottom: "1px solid white",
      fontFamily: "RHSans-Roman !important"
    },
    cookieLanguageSelect: {
      color: "#FFF",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "RHSans !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "120%",
      letterSpacing: "0.44px",
      textTransform: "uppercase",
      display: "flex",
      gap: "12px",
      margin: theme.spacing(0, 4, 1.5, 0),
      [theme.breakpoints.down(BREAKPOINT_660)]: {
        margin: theme.spacing(0, 0, 2, 0)
      }
    },
    acceptButton: {
      backgroundColor: "white",
      border: "1px solid #000",
      color: "#000",
      minWidth: theme.spacing(17.1),
      width: theme.spacing(17.1),
      height: theme.spacing(6),
      minHeight: theme.spacing(6),
      padding: 0,

      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "100%"
      },
      "&:hover": {
        color: "#000",
        backgroundColor: "#fff"
      },
      "&:focus, &:focus-visible, &:focus-within": {
        outline: "1px solid #FFFFFF",
        outlineOffset: "2px"
      }
    },
    alignButtonsEnd: {
      textAlign: "end"
    },
    permissionModalWrapper: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: theme.spacing(0, 7),
      gap: 16,
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        padding: theme.spacing(0, 5)
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        padding: theme.spacing(0, 5)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: theme.spacing(0, 4)
      },
      [theme.breakpoints.down(BREAKPOINT_660)]: {
        gap: 0,
        padding: theme.spacing(0, 4),
        flexWrap: "wrap",
        boxSizing: "border-box"
      }
    },
    langTogglePermissionModalWrapper: {
      padding: theme.spacing(0, 5),
      width: "100%",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: theme.spacing(0, 4)
      }
    },
    order: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        order: 1
      }
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      gap: 16
    },
    breakPointCheckerHorizontalGridWrapper: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      gap: 16,

      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexWrap: "wrap",
        justifyContent: "space-between"
      },

      [theme.breakpoints.down(BREAKPOINT_660)]: {
        display: "none"
      }
    },
    breakPointCheckerHorizontalGrid: {
      display: "flex",

      [theme.breakpoints.down(BREAKPOINT_660)]: {
        display: "none"
      }
    },
    breakPointCheckerVerticalGrid: {
      display: "none",

      "&& > *": {
        paddingLeft: 0,
        paddingRight: 0
      },

      [theme.breakpoints.down(BREAKPOINT_660)]: {
        display: "flex",
        justifyContent: "center"
      }
    },
    permissionText: {
      margin: theme.spacing(0, 6, 0, 0),

      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        margin: theme.spacing(0, 4, 0, 0)
      },

      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        margin: theme.spacing(0, 3, 0, 0)
      },

      [theme.breakpoints.down(BREAKPOINT_660)]: {
        margin: 0
      }
    },
    langTogglePermissionText: {
      margin: theme.spacing(0, 4, 0, 0),
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        margin: theme.spacing(0, 0, 3, 0)
      }
    },
    permissionTextUnderlined: {
      textDecoration: "underline",
      textUnderlinePosition: "under",
      color: "#fff"
    },
    skeletonPermissionText: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        minHeight: "160px"
      }
    },
    skeletonButtons: {
      width: "356px",
      display: "flex",
      gap: "16px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flexDirection: "column",
        marginBottom: "35px"
      }
    }
  })
);

export const CookiePermissionBanner: FC<CookiePermissionBannerProps> = ({
  openCookieSelectionModal,
  cookieLanguageProps = {},
  showBanner
}) => {
  const cookieElementRef = useRef(null);
  const classes = useStyles();
  const prefix = useLocalization();
  const country = getCountryFromUrl();
  const globalStyles = useTypographyStylesByBrand({
    keys: ["rhBaseCaption", "rhBaseBody1", "whiteText"]
  });
  const [cookies, setCookie] = useCookies(["cookie_preferenes_banner_opened"]);
  const { handleDenyAll, handleAcceptAll } = useCookiePermission({});
  const { pageContent } = usePageContent();
  const {
    onSelectLanguage,
    languageTogglePageContent,
    selectedLanguage,
    loading
  } = cookieLanguageProps;
  const [isBannerFocused, setIsBannerFocused] = useState(false);
  const CookieLanguages = useMemo(() => {
    const currentCountry = countries[country];
    const { supportedLanguage } = currentCountry ?? {};
    const filteredLanguages = supportedLanguage?.filter(
      lang => !(supportedLanguage?.length === 1 && lang === "en")
    );
    return filteredLanguages?.map(lang => lang?.toUpperCase());
  }, [country]);

  const showLanguageToggle = !!languageTogglePageContent;
  const bannerContent = languageTogglePageContent ?? pageContent?.cookieModal;

  useEffect(() => {
    if (window && showBanner) {
      setCookie("cookie_preferenes_banner_opened", "true", { path: "/" });

      setIsBannerFocused(true);
      const cookieBannerElement: ReactNode | any = cookieElementRef?.current;
      window.addEventListener("load", () => cookieBannerElement?.focus());

      return () => {
        window.removeEventListener("load", () => cookieBannerElement?.focus());
      };
    }
  }, [showBanner]);

  useEffect(() => {
    const cookieBannerElement: ReactNode | any = cookieElementRef?.current;

    if (cookieBannerElement && showBanner) {
      cookieBannerElement.addEventListener("focusin", () =>
        setIsBannerFocused(true)
      );
      cookieBannerElement.addEventListener("focusout", e => {
        if (!cookieBannerElement.contains(e.relatedTarget)) {
          setIsBannerFocused(false);
        }
      });

      return () => {
        cookieBannerElement.removeEventListener("focusin", () =>
          setIsBannerFocused(true)
        );
        cookieBannerElement.removeEventListener("focusout", e => {
          if (!cookieBannerElement.contains(e.relatedTarget)) {
            setIsBannerFocused(false);
          }
        });
      };
    }
  }, [showBanner]);

  let focusToHamburger = useCallback(() => {
    if (!isBannerFocused) {
      let hamburgerElement = document?.getElementById("hamburgerIcon");
      if (hamburgerElement) {
        hamburgerElement.focus();
      }
    }
  }, []);

  useEffect(() => {
    focusToHamburger();
  }, [!isBannerFocused]);

  const handleAcceptAllCookieButton = useCallback(() => {
    handleAcceptAll();
    setTimeout(() => focusToHamburger(), 200);
  }, []);

  const handleRejectAllCookieButton = useCallback(() => {
    handleDenyAll();
    setTimeout(() => focusToHamburger(), 200);
  }, []);

  return (
    <Grid
      className={classNames(
        classes.permissionModalWrapper,
        {
          [classes.langTogglePermissionModalWrapper]: showLanguageToggle
        },
        "justify-end w-full"
      )}
      id={"component-rh-cookie-permission-banner"}
      data-testid={"component-rh-cookie-permission-banner"}
      tabIndex={!showBanner ? -1 : 0}
      ref={cookieElementRef}
    >
      <Grid item xs={showLanguageToggle ? 12 : false}>
        {showLanguageToggle && CookieLanguages.length !== 0 && (
          <div className={classNames(classes.cookieLanguageSelect)}>
            {CookieLanguages.map(val => (
              <div
                className={classNames(classes.languageSelect, {
                  [classes.languageSelected]: val === selectedLanguage
                })}
                data-value={val}
                onClick={e =>
                  onSelectLanguage?.(e.currentTarget.dataset["value"] || "")
                }
                tabIndex={0}
                onKeyDown={handleKeyboardPressWithEvent(() =>
                  onSelectLanguage?.(val || "")
                )}
              >
                {val}
              </div>
            ))}
          </div>
        )}
        {loading && showLanguageToggle ? (
          <div className={classes.skeletonPermissionText}>
            <Skeleton className={"opacity-[0.5]"} variant="text" width="90%" />
            <Skeleton className={"opacity-[0.5]"} variant="text" width="60%" />
          </div>
        ) : (
          <p
            className={classNames(
              globalStyles.rhBaseBody1,
              globalStyles.whiteText,
              classes.permissionText,
              {
                [classes.langTogglePermissionText]: showLanguageToggle
              }
            )}
          >
            {bannerContent?.splitTextOne}
            {/* this routing will be cookie policy page when completed */}
            <a
              href={`${prefix}${COOKIES_URL}`}
              className={classes.permissionTextUnderlined}
            >
              {bannerContent?.splitTextTwo}
            </a>
          </p>
        )}
      </Grid>
      {loading && showLanguageToggle ? (
        <div className={classes.skeletonButtons}>
          <Skeleton
            className={"opacity-[0.5]"}
            variant="rectangular"
            height={"48px"}
            width={"100%"}
          />
          <Skeleton
            className={"opacity-[0.5]"}
            variant="rectangular"
            height={"48px"}
            width={"100%"}
          />
        </div>
      ) : (
        <>
          <Grid className={classes.breakPointCheckerHorizontalGridWrapper}>
            <Grid
              className={classNames(
                classes.breakPointCheckerHorizontalGrid,
                classes.order
              )}
            >
              <Typography
                className={classes.manageButton}
                tabIndex={0}
                onKeyDown={handleKeyboardPressWithEvent(() =>
                  openCookieSelectionModal(true)
                )}
                onClick={() => openCookieSelectionModal(true)}
                role="button"
              >
                {bannerContent?.MANAGE}
              </Typography>
            </Grid>
            <Grid className={classes.flexContainer}>
              <Grid className={classes.breakPointCheckerHorizontalGrid}>
                <Button
                  variant="outlined"
                  fullWidth
                  className={classNames(
                    classes.acceptButton,
                    globalStyles.rhBaseCaption
                  )}
                  onClick={handleAcceptAllCookieButton}
                >
                  {bannerContent?.ACCEPT_ALL}
                </Button>
              </Grid>
              <Grid className={classes.breakPointCheckerHorizontalGrid}>
                <Button
                  variant="outlined"
                  fullWidth
                  className={classNames(
                    classes.acceptButton,
                    globalStyles.rhBaseCaption
                  )}
                  onClick={handleRejectAllCookieButton}
                >
                  {bannerContent?.DENY_ALL}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            sm={12}
            md={12}
            className={classes.breakPointCheckerVerticalGrid}
          >
            <Grid
              xs={12}
              sm={6}
              md={6}
              item
              className={classes.alignButtonsEnd}
            >
              <Button
                variant="outlined"
                fullWidth
                className={classes.acceptButton}
                onClick={handleAcceptAllCookieButton}
              >
                {bannerContent?.ACCEPT_ALL}
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={6}
              item
              className={classes.alignButtonsEnd}
            >
              <Button
                variant="outlined"
                fullWidth
                className={classes.acceptButton}
                onClick={handleRejectAllCookieButton}
              >
                {bannerContent?.DENY_ALL}
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={6}
              item
              className={classes.alignButtonsEnd}
            >
              <Typography
                className={classes.manageButton}
                onClick={() => openCookieSelectionModal(true)}
                role="button"
              >
                {bannerContent?.MANAGE}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default memoize(CookiePermissionBanner);
