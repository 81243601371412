import React, { FC, Fragment, useEffect, useState, CSSProperties } from "react";
import he from "he";
import sortBy from "lodash.sortby";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Theme,
  Typography,
  useTheme,
  Grid,
  useMediaQuery
} from "@mui/material";
import { formatCurrency } from "utils/currencyHelpers";
import {
  FONT_BARON_SANS_LIGHT,
  FONT_BARON_SANS_THIN,
  FONT_MEDIUM
} from "utils/constants";
import OptionLabelMobile from "./OptionLabelMobile";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useEnv } from "hooks/useEnv";
import { usePageContent } from "customProviders/LocationProvider";
import { useParams2 } from "hooks/useParams";
import useRHstyles from "hooks/useRHStyles";
import { processEnvServer } from "hooks/useSsrHooks";
import { getReqContext } from "utils/reqContext";
import { useAppId } from "hooks/useAppId";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      justifyContent: "center"
    },
    rhrline: {
      alignItems: "flex-start",
      flexWrap: "nowrap",
      marginBottom: 16
    }
  })
);

export interface OptionLabelsProps {
  label: string;
  value: any;
  centerAlignFlag: boolean;
  typeWidth: number;
  labelStyles?: CSSProperties;
  valueStyles?: CSSProperties;
  onCalculateTypeWidth: (typeWidth: number) => void;
  isNewPDPV2?: boolean;
}

const OptionLabels: FC<OptionLabelsProps> = memoize(
  ({
    label,
    value,
    typeWidth,
    labelStyles,
    valueStyles,
    centerAlignFlag,
    onCalculateTypeWidth
  }) => {
    const classes = useStyles();
    const { isConcierge } = useAppId();
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const [typeMinWidth, setTypeMinWidth] = useState<number>(0);
    const [numberOfLines, setNumberOfLines] = useState<number>(1);
    const req = getReqContext();
    const isCartPage =
      (!processEnvServer
        ? location.pathname?.includes("/checkout/shopping_cart.jsp")
        : req?.path?.includes("/checkout/shopping_cart.jsp")) || false;
    const getMinWidth = (optionsRef: HTMLDivElement | null) => {
      if (!!optionsRef) {
        setTypeMinWidth(optionsRef.getBoundingClientRect().width / 2 + 5);
      }
    };

    const getMaxTypeWidth = (typeRef: HTMLDivElement | null) => {
      if (!!typeRef) {
        onCalculateTypeWidth(typeRef.getBoundingClientRect().width + 5);
      }
    };

    const getOptionLabelHeight = (typeRef: HTMLDivElement | null) => {
      if (!!typeRef) {
        const lineHeight = 18;
        const containerHeight = typeRef?.clientHeight;
        const numLines = Math.round(containerHeight / lineHeight);
        setNumberOfLines(numLines);
      }
    };

    const labelWidth = typeWidth < typeMinWidth ? typeWidth : typeMinWidth;
    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseBody1", "rhBaseBody2", "rhBaseCaption"]
    });

    const featureCartStyles = isCartPage
      ? {
          justifyContent: numberOfLines > 1 ? "center" : "flex-start",
          height:
            numberOfLines > 1 ? `${18 + (numberOfLines - 1) * 12}px` : "18px",
          lineHeight: numberOfLines > 1 ? "12.1px" : "18px"
        }
      : {
          width: centerAlignFlag
            ? "auto"
            : typeWidth > 0
            ? labelWidth
            : "unset",
          marginRight: typeWidth > 0 ? 5 : "unset",
          minWidth: typeWidth > 0 ? labelWidth : "unset"
        };
    return isCartPage || !centerAlignFlag ? (
      <Grid
        ref={getMinWidth}
        item
        container
        className={"flex !items-center !flex-nowrap"}
        style={{
          justifyContent: centerAlignFlag ? "center" : "left !important",
          textAlign: centerAlignFlag ? "end" : "left"
        }}
      >
        <span
          ref={getOptionLabelHeight}
          className={`${
            isCartPage ? "min-w-[90px] w-[90px]" : "min-w-[45px] w-[45px]"
          } h-auto absolute invisible`}
          style={{ ...labelStyles }}
        >
          {label}
        </span>
        <Grid
          item
          xs={isCartPage || isConcierge ? false : 2}
          style={{ flexBasis: "unset" }}
          className={"!max-w-[74px]"}
        >
          <Typography
            id={`listColumn1-${label}`}
            ref={getMaxTypeWidth}
            className={`${!isCartPage ? "inline-block" : ""} ${
              typographyStyles.rhBaseBody1
            } ${
              isCartPage
                ? `!flex flex-col min-w-[90px] w-[90px] !mr-[8px] min-h-[18px]`
                : `text-[#898886] text-[13px] ${
                    !isCartPage ? "min-w-[45px] w-[45px]" : ""
                  }`
            }`}
            style={{
              ...labelStyles,
              ...featureCartStyles
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          xs={isCartPage || isConcierge ? false : 10}
          style={{ flexBasis: "unset" }}
        >
          <Typography
            id={`listColumn2-${label}`}
            className={`${!isCartPage ? "inline-block" : ""} ${
              typographyStyles.rhBaseBody2
            }`}
            style={{
              ...valueStyles,
              textAlign: "left",
              ...(isCartPage
                ? {
                    width:
                      typeWidth < 90 ? "256px" : `calc(100% - ${labelWidth}px)`
                  }
                : {
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontFamily: ""
                  }),
              width: "100%"
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid
        ref={getMinWidth}
        container
        style={{
          width: "100%",
          justifyContent: isCartPage ? "left" : "center"
        }}
        spacing={1}
      >
        <Grid item>
          <Typography id={`listColumn1-${label}`} ref={getMaxTypeWidth}>
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            id={`listColumn2-${label}`}
            className={`${!isCartPage ? "inline-block" : ""} ${
              typographyStyles.rhBaseBody2
            }`}
            style={{
              ...valueStyles,
              ...(isCartPage
                ? {
                    width:
                      typeWidth < 90 ? "256px" : `calc(100% - ${labelWidth}px)`
                  }
                : {
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontFamily: ""
                  }),
              width: "100%"
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

const RHROptionLabels: FC<OptionLabelsProps> = memoize(
  ({ label, value, typeWidth, onCalculateTypeWidth, centerAlignFlag }) => {
    const classes = useStyles();

    const [typeMinWidth, setTypeMinWidth] = useState<number>(0);

    const getMinWidth = (optionsRef: HTMLDivElement | null) => {
      if (!!optionsRef) {
        setTypeMinWidth(optionsRef.getBoundingClientRect().width / 2 + 5);
      }
    };

    const getMaxTypeWidth = (typeRef: HTMLDivElement | null) => {
      if (!!typeRef) {
        onCalculateTypeWidth(typeRef.getBoundingClientRect().width + 5);
      }
    };

    const labelWidth = typeWidth < typeMinWidth ? typeWidth : typeMinWidth;

    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseCaption"]
    });
    return (
      <Grid
        ref={getMinWidth}
        item
        container
        style={{
          alignItems: "flex-start",
          flexWrap: "nowrap",
          marginBottom: 16,
          justifyContent: centerAlignFlag ? "center" : "left"
        }}
      >
        <Typography
          id={`listColumn1-${label}`}
          ref={getMaxTypeWidth}
          className={typographyStyles.rhBaseCaption}
          style={{
            width: typeWidth > 0 ? labelWidth : "unset",
            marginRight: typeWidth > 0 ? 30 : "unset",
            textTransform: "uppercase",
            fontWeight: 400,
            color: "#808080",
            fontSize: 11,
            fontFamily: FONT_BARON_SANS_LIGHT,
            wordBreak: "keep-all"
          }}
        >
          {label}
        </Typography>
        <Typography
          id={`listColumn2-${label}`}
          className={typographyStyles.rhBaseCaption}
          style={{
            textTransform: "uppercase",
            fontWeight: 400,
            fontSize: 11,
            fontFamily: FONT_BARON_SANS_LIGHT
          }}
        >
          {value}
        </Typography>
      </Grid>
    );
  }
);

export interface OptionsDetailsListProps {
  itemId?: Maybe<string>;
  isStocked?: boolean;
  centerAlignFlag?: boolean;
  options: ProductItemOption[];
  price?: Maybe<ItemPricing>;
  qty?: number;
  uppercase?: boolean;
  onWidthChange?: (value: number) => void;
}

export const OptionsDetailsList: FC<OptionsDetailsListProps> = memoize(
  ({
    itemId,
    options = [],
    price,
    isStocked,
    centerAlignFlag,
    qty = 0,
    uppercase = true,
    isNewPDPV2,
    onWidthChange = () => {}
  }) => {
    const theme = useTheme();
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const req = getReqContext();
    const isCartPage =
      (!processEnvServer
        ? location.pathname?.includes("/checkout/shopping_cart.jsp")
        : req?.path?.includes("/checkout/shopping_cart.jsp")) || false;
    const [typeWidth, setTypeWidth] = useState<number>(0);
    const { params: queryParams } = useParams2<{ [key: string]: string }>(
      { version: "" },
      { toLowerCase: true }
    );
    const isNewPDPLayout = queryParams?.version === "v2" || isNewPDPV2;
    const env = useEnv();
    const styles: { [id: string]: CSSProperties } = {
      listTotalPrice: {
        textTransform: "initial",
        fontWeight: FONT_MEDIUM,
        color: theme.palette.common.black
      },
      smlistColumn1: {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#999999"
      },
      listColumn1: {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#898886"
      },
      personalizedlistColumn3: {
        fontSize: theme.typography.pxToRem(11),
        fontFamily: FONT_BARON_SANS_THIN,
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "rgba(137, 136, 134, 1)"
      },
      personalizedValueColumn4: {
        fontSize: theme.typography.pxToRem(11),
        fontFamily: FONT_BARON_SANS_THIN,
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        // textTransform: uppercase ? "uppercase" : "none",
        color: "rgb(0,0,0)"
      },
      smlistColumn2: {
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#999999"
      },
      listColumn2: {
        fontSize: 12,
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: uppercase ? "uppercase" : "none",
        color: "#000000",
        marginRight: 5
      },
      listColumnMonogram: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: "0.2px",
        textTransform: "initial",
        color: "#666666"
      },
      personalizeLabelColumn1: {
        fontFamily: FONT_BARON_SANS_LIGHT,
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(13),
        color: "#808080",
        letterSpacing: "0.04em",
        textTransform: "uppercase",
        fontWeight: 400,
        marginBottom: 8
      },
      personalizeValueColumn2: {
        fontFamily: FONT_BARON_SANS_LIGHT,
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(13),
        color: "#000000",
        letterSpacing: "0.04em",
        textTransform: "uppercase",
        fontWeight: 400,
        marginBottom: 16
      },
      optionListColumnLable1: {
        color: "#898886",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: FONT_BARON_SANS_LIGHT,
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "18px",
        letterSpacing: "0.44px",
        textTransform: "uppercase"
      },
      optionListColumnValue2: {
        color: "#000",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: FONT_BARON_SANS_LIGHT,
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "18px",
        letterSpacing: "0.44px",
        textTransform: "capitalize"
      }
    };
    const rhClasses = useRHstyles({
      keys: ["tripleBottomMargin"]
    });

    let maxTypeWidth = 0;
    const calculateTypeWidth = (typeWidth: number) => {
      maxTypeWidth = Math.max(typeWidth, maxTypeWidth);
    };

    useEffect(() => {
      setTypeWidth(maxTypeWidth);
      onWidthChange(maxTypeWidth);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { pageContent } = usePageContent();

    return (
      <>
        <Grid container id={"component-options-details-list"}>
          {!isCartPage && !!itemId && !isStocked && (
            <>
              {xsDown ? (
                <OptionLabelMobile
                  label={pageContent?.ITEM || "Item #"}
                  value={itemId}
                  labelStyles={styles.smlistColumn1}
                  valueStyles={styles.smlistColumn2}
                />
              ) : env.FEATURE_PDP ? (
                <RHROptionLabels
                  label={pageContent?.ITEM || "Item #"}
                  value={itemId}
                  typeWidth={typeWidth}
                  onCalculateTypeWidth={calculateTypeWidth}
                  centerAlignFlag={centerAlignFlag}
                />
              ) : (
                <OptionLabels
                  label={pageContent?.ITEM || "Item #"}
                  value={itemId}
                  typeWidth={typeWidth}
                  onCalculateTypeWidth={calculateTypeWidth}
                  labelStyles={styles.personalizedlistColumn3}
                  valueStyles={styles.personalizedValueColumn4}
                  centerAlignFlag={centerAlignFlag}
                />
              )}
            </>
          )}

          {sortBy(options as Array<ProductItemOption>, [
            option => option?.optionTypePriority ?? option.sortPriority
          ]).map(({ id, optionType, label }) => {
            return (
              <Fragment key={`optionType-${itemId}-${id}`}>
                {xsDown && !isNewPDPLayout ? (
                  <OptionLabelMobile
                    labelStyles={
                      isCartPage
                        ? styles.optionListColumnLable1
                        : styles.personalizeLabelColumn1
                    }
                    valueStyles={
                      isCartPage
                        ? styles.optionListColumnValue2
                        : styles.personalizeValueColumn2
                    }
                    label={optionType}
                    value={label}
                  />
                ) : env.FEATURE_PDP ? (
                  <RHROptionLabels
                    label={optionType}
                    value={he.decode(label)}
                    typeWidth={typeWidth}
                    onCalculateTypeWidth={calculateTypeWidth}
                    centerAlignFlag={centerAlignFlag}
                  />
                ) : (
                  <OptionLabels
                    label={optionType}
                    value={he.decode(label)}
                    typeWidth={typeWidth}
                    onCalculateTypeWidth={calculateTypeWidth}
                    labelStyles={
                      isCartPage
                        ? styles.optionListColumnLable1
                        : styles.personalizedlistColumn3
                    }
                    valueStyles={
                      isCartPage
                        ? styles.optionListColumnValue2
                        : styles.personalizedValueColumn4
                    }
                    centerAlignFlag={centerAlignFlag}
                  />
                )}
              </Fragment>
            );
          })}
        </Grid>
        {isCartPage && !!itemId && !isStocked && (
          <>
            {xsDown ? (
              <OptionLabelMobile
                label={pageContent?.ITEM || "Item #"}
                value={itemId}
                labelStyles={styles.optionListColumnLable1}
                valueStyles={styles.optionListColumnValue2}
                className={rhClasses.tripleBottomMargin}
              />
            ) : env.FEATURE_PDP ? (
              <RHROptionLabels
                label={pageContent?.ITEM || "Item #"}
                value={itemId}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                centerAlignFlag={centerAlignFlag}
              />
            ) : (
              <OptionLabels
                label={pageContent?.ITEM || "Item #"}
                value={itemId}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                labelStyles={styles.optionListColumnLable1}
                valueStyles={styles.optionListColumnValue2}
                centerAlignFlag={centerAlignFlag}
              />
            )}
          </>
        )}
        {price && (
          <Grid container style={{ marginTop: theme.spacing(2) }}>
            {smDown ? (
              <OptionLabelMobile
                label={pageContent?.PRICE}
                value={formatCurrency(
                  price.actualAmount?.amount || 0,
                  price.actualAmount?.currency || "USD",
                  true
                )}
                labelStyles={
                  isCartPage
                    ? styles.optionListColumnLable1
                    : styles.personalizedlistColumn3
                }
                valueStyles={
                  isCartPage
                    ? styles.optionListColumnValue2
                    : styles.personalizedValueColumn4
                }
              />
            ) : env.FEATURE_PDP ? (
              <RHROptionLabels
                label={pageContent?.PRICE}
                value={formatCurrency(
                  price.actualAmount?.amount || 0,
                  price.actualAmount?.currency || "USD",
                  true
                )}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                centerAlignFlag={centerAlignFlag}
              />
            ) : (
              <OptionLabels
                label={pageContent?.PRICE}
                value={formatCurrency(
                  price.actualAmount?.amount || 0,
                  price.actualAmount?.currency || "USD",
                  true
                )}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                labelStyles={
                  isCartPage
                    ? styles.optionListColumnLable1
                    : styles.personalizedlistColumn3
                }
                valueStyles={
                  isCartPage
                    ? styles.optionListColumnValue2
                    : styles.personalizedValueColumn4
                }
                centerAlignFlag={centerAlignFlag}
              />
            )}
            {smDown ? (
              <OptionLabelMobile
                label={pageContent?.TOTAL}
                value={formatCurrency(
                  qty * (price.actualAmount?.amount || 0),
                  price.actualAmount?.currency || "USD",
                  true
                )}
                labelStyles={styles.listTotalPrice}
                valueStyles={styles.listTotalPrice}
              />
            ) : env.FEATURE_PDP ? (
              <RHROptionLabels
                label={pageContent?.TOTAL}
                value={formatCurrency(
                  qty * (price.actualAmount?.amount || 0),
                  price.actualAmount?.currency || "USD",
                  true
                )}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                centerAlignFlag={centerAlignFlag}
              />
            ) : (
              <OptionLabels
                label={pageContent?.TOTAL}
                value={formatCurrency(
                  qty * (price.actualAmount?.amount || 0),
                  price.actualAmount?.currency || "USD",
                  true
                )}
                typeWidth={typeWidth}
                onCalculateTypeWidth={calculateTypeWidth}
                labelStyles={styles.listTotalPrice}
                valueStyles={styles.listTotalPrice}
                centerAlignFlag={centerAlignFlag}
              />
            )}
          </Grid>
        )}
      </>
    );
  }
);

export default OptionsDetailsList;
