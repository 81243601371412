import React, { FC, useRef, useEffect } from "react";
import {
  useTheme,
  ImageListItem,
  ImageListProps,
  useMediaQuery,
  Theme
} from "@mui/material";
import PlayIcon from "@RHCommerceDev/icon-play";
import RHCarousel from "component-rh-carousel";
import { ArrowsVisible } from "component-rh-carousel/types";
import RHImage from "@RHCommerceDev/rh-image-component";
import { IMAGE_ASPECT_RATIO } from "@RHCommerceDev/utils/constants";

export interface ImageThumbnailListProps
  extends Omit<ImageListProps, "children"> {
  direction?: "row" | "column";
  images: ProductAlternateImage[];
  index: number | undefined;
  imageCarousels?: boolean;
  onChangeIndex?: (index: number) => void;
  parentBaseId?: string;
  infiniteScroll?: boolean;
  isPdp?: Boolean;
  isZoomViewer?: Boolean;
}

export const ImageThumbnailList: FC<ImageThumbnailListProps> = ({
  images,
  index,
  onChangeIndex,
  imageCarousels,
  infiniteScroll = true,
  isPdp,
  isZoomViewer = false
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const carouselProps = {
    slidesToShow:
      images?.length >=
      (!imageCarousels && mdUp ? 3 : !imageCarousels && !mdUp ? 3 : 5)
        ? !imageCarousels && mdUp
          ? 3
          : !mdUp
          ? 3
          : 5
        : images.length % 5,

    vertical: !imageCarousels && mdUp ? true : false,
    verticalSwiping: !imageCarousels && mdUp ? true : false,
    arrowsVisible: "always" as ArrowsVisible
  };
  useEffect(() => {
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        inline: "center",
        behavior: "smooth"
      });
    });
  }, [ref]);

  return (
    <>
      <div
        id={"component-image-thumbnail-list"}
        key={"component-image-thumbnail-list"}
        data-testid={"component-image-thumbnail-list"}
        className={` ${
          !imageCarousels && mdUp
            ? "w-full"
            : images?.length === 4 && mdUp
            ? "w-[82%]"
            : images?.length === 3
            ? !imageCarousels && !mdUp
              ? "w-full"
              : "w-[60%]"
            : images?.length === 2
            ? !imageCarousels && !mdUp
              ? "w-[60%]"
              : "w-[40%]"
            : images?.length === 1
            ? "w-[30%]"
            : !mdUp
            ? "w-full"
            : "w-[98%]"
        }`}
      >
        <RHCarousel
          {...carouselProps}
          arrowsSize="normal"
          arrowZoomImage={!imageCarousels ? true : false}
          imageCarousels={imageCarousels}
          index={index}
          infinite={infiniteScroll}
          isZoomViewer={isZoomViewer}
        >
          {images?.map((image, i) => (
            <ImageListItem
              key={`altImage-${i}`}
              onClick={() => {
                onChangeIndex?.(i);
              }}
            >
              <div
                style={{
                  margin:
                    !imageCarousels && mdUp
                      ? theme.spacing(0.5)
                      : theme.spacing(0.8, 0.8, 2),
                  paddingBottom: isPdp
                    ? "0px"
                    : !imageCarousels && mdUp
                    ? "0px"
                    : theme.spacing(1),
                  position: "relative",
                  boxSizing: "border-box",
                  overflow: "auto",
                  overflowY: "hidden",
                  maxWidth: !imageCarousels ? "auto" : "350px",
                  outline: "none"
                }}
              >
                <RHImage
                  src={image.imageUrl}
                  className={
                    i === index
                      ? "border-solid border-[1px] border-black p-[2px] object-contain !h-auto"
                      : "object-contain"
                  }
                  // clientlib-base.css file overrides the margin of the image to reset it to 0
                  style={{
                    margin: 0,
                    aspectRatio: `${IMAGE_ASPECT_RATIO["thumbnail"]}`
                  }}
                  preset="mobileThumb"
                  loading="eager"
                  skeletonComponent={() => null}
                />
                {
                  <>
                    {!!image.video && (
                      <PlayIcon className="absolute left-0 right-0 top-0 bottom-0 m-auto text-white pointer-events-none !text-[40px]" />
                    )}
                  </>
                }
              </div>
            </ImageListItem>
          ))}
        </RHCarousel>
      </div>
    </>
  );
};

ImageThumbnailList.defaultProps = {};

export default ImageThumbnailList;
