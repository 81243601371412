import React, { FC } from "react";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_660,
  BREAKPOINT_SM
} from "utils/constants";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Grid } from "@mui/material";
import memoize from "utils/memoize";
import { useEnv } from "hooks/useEnv";
import { useAppId } from "hooks/useAppId";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { languageToggleExcludedCountries } from "resources/countries-config.json";
import classNames from "classnames";
import yn from "yn";

interface RHStickyModalProps {
  children?: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      position: "fixed",
      left: theme.spacing(0),
      right: theme.spacing(0),
      width: "calc(100% - 80px)",
      padding: theme.spacing(3, 0),
      backgroundColor: "rgb(0,0,0,0.85)",
      display: "flex",
      margin: "0px auto",
      bottom: theme.spacing(5),
      zIndex: 9999,

      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        padding: theme.spacing(3.75, 0)
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        width: "100%",
        margin: theme.spacing(0)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        bottom: theme.spacing(4)
      },
      [theme.breakpoints.down(BREAKPOINT_660)]: {
        width: "100%",
        margin: 0,
        height: "335px",
        padding: theme.spacing(3, 0, 2.5)
      }
    },
    langToggleMainWrapper: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "100%",
        margin: 0,
        height: "auto",
        padding: theme.spacing(3, 0, 3)
      }
    }
  })
);

const RHStickyModal: FC<RHStickyModalProps> = memoize(({ children }) => {
  const env = useEnv();
  const classes = useStyles();
  const { isEstore } = useAppId();
  const country = getCountryFromUrl();
  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    env?.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const showLanguageToggle =
    isEstore &&
    !languageToggleExcludedCountries.includes(country) &&
    !FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE;
  return (
    <Grid
      container
      className={classNames(classes.mainWrapper, {
        [classes.langToggleMainWrapper]: showLanguageToggle
      })}
      id={"component-rh-sticky-modal"}
    >
      {children}
    </Grid>
  );
});

export default RHStickyModal;
