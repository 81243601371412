import React, { FC, forwardRef, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Link } from "@mui/material";
import memoize from "@RHCommerceDev/utils/memoize";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { isSSRToggledCheck } from "@RHCommerceDev/utils/ssrHelpers";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { isForcedReloadPath } from "@RHCommerceDev/utils/buildPath";
import RHUnderline from "@RHCommerceDev/component-rh-underline";

export type RHLinkProps = any;
export type RHLinkButtonProps = any;

export const RHLinkButton: FC<RHLinkButtonProps> = forwardRef(
  ({ to, children, component = "a", ...rest }, ref) => {
    return (
      <Button
        id={"component-rh-link_button"}
        {...rest}
        component={to ? RouterLink : (component as any)}
        to={to}
      >
        {children}
      </Button>
    );
  }
);

export const RHLink = forwardRef<any, any>(
  (
    {
      to,
      children,
      component = "a",
      cqPath,
      disabled,
      customPrefix,
      customStyle,
      isNavLink,
      //focusOutlineStyle,
      //focusOutlineWidth,
      //focusOutlineColor,
      bypassLocalization,
      bypassForceReload = false,
      isModalTrigger,
      id,
      ariaLabel,
      ...rest
    },
    ref
  ) => {
    const env = useEnv();
    const linkRef = useRef(null);
    /*const { isFocused, focusProps, accessibilityProps } =
      useAccessibilityFocusRectangle({
        focusOutlineStyle,
        focusOutlineColor,
        focusOutlineWidth
      });
    */
    const linkADALabel =
      rest?.["bannerName"] || rest?.["ctaName"] || ariaLabel || "Link";
    const prefix = useLocalization();
    const finalPrefix = !!customPrefix ? customPrefix : prefix;
    const { forcedReloadPath } = isForcedReloadPath(to);
    const isSSRToggled = isSSRToggledCheck();
    const isPgForcedReload = isSSRToggled && to && forcedReloadPath;
    let newTo =
      isPgForcedReload && typeof to !== "string"
        ? to?.pathname || "" + to?.search + to?.hash
        : to === undefined
        ? ""
        : to;
    if (!bypassLocalization && newTo && typeof newTo === "string") {
      newTo = newTo.includes(finalPrefix) ? newTo : finalPrefix + newTo;
    }
    return disabled ? (
      <RHUnderline
        style={{ cursor: "not-allowed", textDecoration: "none" }}
        id={id || "component-rh-link_disabled"}
      >
        {children}
      </RHUnderline>
    ) : isPgForcedReload && !bypassForceReload ? (
      <a
        {...rest}
        id={id || "component-rh-link"}
        //{...focusProps}
        style={{
          ...(customStyle
            ? { ...rest.style, color: undefined } // Remove color property
            : { textDecoration: "none" })
          //...(isFocused ? accessibilityProps : {})
        }}
        href={rest?.href ? rest?.href : newTo}
        data-analytics-id="link"
        aria-label={linkADALabel}
      >
        {children}
      </a>
    ) : (
      <Link
        id={id || "component-rh-link"}
        ref={linkRef}
        //{...focusProps}
        style={{
          ...rest.style
          //...(isFocused ? accessibilityProps : {})
        }}
        component={
          newTo && newTo !== ""
            ? rest.href
              ? component
              : RouterLink
            : (component as any)
        }
        to={rest.href ? rest.href : newTo}
        href={rest.href ? rest.href : undefined}
        data-analytics-id="link"
        aria-label={linkADALabel}
        // onKeyDown={handleKeyboardPressWithEvent(() => linkRef.current.click())}
        underline="hover"
        {...rest}
      >
        {children}
      </Link>
    );
  }
);

RHLink.defaultProps = {};

export default memoize(RHLink);
