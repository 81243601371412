import React, { FC } from "react";
import { Typography, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LayerProps } from "./";
import classNames from "classnames";
import getPositionAttributes from "./getPositionAttributes";
import parse from "style-to-object";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      transition: "all 1s ease",
      overflowX: "hidden",
      zIndex: 1
    }
  })
);

export const LiveTextLayer: FC<LayerProps> = ({ layer }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classNames(classes.root)}
      style={{
        ...getPositionAttributes(layer.dynamicText),
        ...parse(layer.dynamicText.styles ?? "")
      }}
      dangerouslySetInnerHTML={{ __html: layer.htmlText }}
    />
  );
};

export default LiveTextLayer;
