import useState from "hooks/useState";
import React, { FC, ImgHTMLAttributes } from "react";
import { useTheme } from "@mui/material";
import spinner from "./spinner.svg";

export interface RHSpinnerProps extends ImgHTMLAttributes<HTMLImageElement> {
  color?: "black" | "white";
  isSmallView?: boolean;
  style?: any;
}

const RHSpinner: FC<RHSpinnerProps> = ({
  color = "black",
  style,
  isSmallView,
  ...rest
}) => {
  const theme = useTheme();
  const [error, setError] = useState(false);

  if (error) {
    return null;
  }
  return (
    <img
      id={"component-rh-spinner"}
      data-testid="rh-spinner"
      alt="spinner"
      {...rest}
      src={spinner}
      style={{
        ...style
      }}
      //isSmallView - flag that adjusts the spinner height according to component component.
      className={`${
        isSmallView
          ? " xs:!max-h-[26px] sm:!max-h-[13px] md:!max-h-[39px] lg:!max-h-[26px] xl:!max-h-[13px] !m-auto !max-w-[64px]  !pointer-events-none"
          : "!absolute !m-auto !left-0 !right-0 !top-0 !bottom-0 !max-w-[64px] !max-h-[64px] !pointer-events-none"
      }`}
      onError={() => setError(true)}
    />
  );
};

RHSpinner.defaultProps = {};

export default RHSpinner;
