import React, { FC } from "react";
import { Fade, Popper } from "@mui/material";
import RHShippingSelections from "component-rh-shipping-selections";
import memoize from "utils/memoize";
export interface RHShippingToPopperProps {
  isOpen: boolean;
  setIsOpen?: (open: boolean) => void;
  anchorEl?: HTMLElement | null;
}

export const RHShippingToPopper: FC<RHShippingToPopperProps> = ({
  isOpen,
  setIsOpen,
  anchorEl
}) => {
  const canBeOpen = isOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <Popper
      id={id}
      anchorEl={anchorEl}
      open={isOpen}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 12]
          }
        }
      ]}
      transition
      style={{ zIndex: 10000 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <RHShippingSelections setIsOpen={setIsOpen} />
        </Fade>
      )}
    </Popper>
  );
};

export default memoize(RHShippingToPopper);
