import React, { FC } from "react";
import { MobilePromoLayerProps } from "component-promo/MobileLayers";
import { Typography } from "@mui/material";

export const LiveTextLayer: FC<MobilePromoLayerProps> = ({ layer }) => {
  return (
    <Typography
      style={{
        color: "inherit"
      }}
      dangerouslySetInnerHTML={{ __html: layer.htmlText }}
    ></Typography>
  );
};

LiveTextLayer.defaultProps = {};

export default LiveTextLayer;
