import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useLazyQuery } from "@apollo/client";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import RHSpinner from "component-rh-spinner";
import { queryProductImage } from "graphql-client/queries/product-image";
import { useAppId } from "hooks-use-app-id";
import { useEnv } from "hooks/useEnv";
import useLocale from "hooks/useLocale/useLocale";
import { useLocalization } from "hooks/useLocalization";
import useSite from "hooks/useSite";
import {
  processEnvServer as isServer,
  processEnvServer
} from "hooks/useSsrHooks";
import useTypographyStyles from "hooks/useTypographyStyles";
import { COLOR_PREVIEW_AVAILABLE_SOON } from "resources/page-level-resources-schemas/products.json";
import memoize from "utils/memoize";
import stringToObject from "utils/stringToObject";
import yn from "yn";
import { getUrl } from "..";
import { PD } from "../ProductDetails";
import { useNewURLStructureParams } from "hooks/useParams";
import ImageCarousel from "component-product-grid/ImageCarousel";
import { getPresetMap } from "@RHCommerceDev/component-rhr-product-card/helper";
import RHImage from "@RHCommerceDev/rh-image-component";
import { sleep } from "@RHCommerceDev/utils/sleep";
import useParams from "@RHCommerceDev/hooks/useParams";
import { getReqContext } from "utils/reqContext";
import { BCT_PATHS, SELECTED_BRAND_COOKIE } from "utils/constants";
import RHLink from "@RHCommerceDev/component-rh-link";
import Cookies from "universal-cookie";
import { useCookies } from "hooks/useCookies";
interface ProductCard {
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  gridColumns: any;
  totalNumRecs: number;
  host?: string;
  item?: SearchResultRecord;
  isSale?: boolean;
  isSaleFilterEnabled?: boolean;
  filterQueries?: string[];
  pageContent?: any;
  onProductClick: Function;
  productTitle?: string;
  inStockFlow?: boolean;
  isSelectedItem?: boolean;
}

export const ProductCard: FC<ProductCard> = memo(
  ({
    item,
    gridColumns,
    isStockedFilterActive,
    isRefinementFilterActive,
    totalNumRecs,
    host = "",
    isSale,
    isSaleFilterEnabled,
    filterQueries,
    pageContent,
    productTitle,
    onProductClick,
    inStockFlow,
    isSelectedItem
  }) => {
    const env = useEnv();
    const siteId = useSite();
    const locale = useLocale();
    const prefix = useLocalization();
    const { isConcierge } = useAppId();
    const isPGColorizationEnabled = yn(env.FEATURE_PG_COLORIZATION);

    const [selectedSwatchIdx, setSelectedSwatchIdx] = useState(-1);
    const [selectedSwatch, setSelectedSwatch] = useState<string | null>(null);
    const [isSWatchClicked, setIsWatchClicked] = useState(false);
    const [IsClicked, setIsClicked] = useState(false);
    const [isSwatchFinish, setIsSwatchFinish] = useState(false);
    const nextgenCookie = "true";
    const isNewURLFeatureEnabled = yn(env.FEATURE_URL_CHANGE);
    const { category } = useNewURLStructureParams();

    const [isSwatchSelected, setIsSwatchSelected] = useState(false);

    const [isColorizable, setIsColorizable] = useState(false);

    const isRHR = item?.product?.rhr ?? false;
    const isRHRImage = isRHR;
    const isConciergeRHRImage = isConcierge && isRHR;
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
    const req = getReqContext();
    const params = useParams({
      site: ""
    });

    const searchPage =
      (!processEnvServer
        ? location.pathname?.includes("/search/")
        : req?.path?.includes("/search/")) || false;

    const [openInNewTab, setOpenInNewTab] = useState(false);

    const typographyStyles = useTypographyStyles({
      keys: [
        "rhBaseBody1",
        "rhBaseH2",
        "rhBaseCaption",
        "rhBaseBody2",
        "rhBaseBody3",
        "rhBaseCaption1",
        "rhBaseCaption2"
      ]
    });

    useEffect(() => {
      setIsColorizable(item?.product?.colorizable ?? false);
    }, [item?.product?.colorizable]);

    const getSite = (params, productUrl) => {
      if (params?.site) return params?.site;
      else if (productUrl?.includes("rhbc_prod")) return "BC";
      else if (productUrl?.includes("rhtn_prod")) return "TN";
      return "RH";
    };

    const to = useMemo(() => {
      let productUrl = getUrl(
        item,
        host,
        isStockedFilterActive,
        isRefinementFilterActive,
        totalNumRecs,
        Boolean(isSale || isSaleFilterEnabled),
        isConcierge,
        filterQueries,
        selectedSwatch,
        nextgenCookie === "true",
        inStockFlow,
        isNewURLFeatureEnabled,
        category
      )?.to;
      const site = searchPage ? getSite(params, productUrl) : siteId;
      const bctPath = BCT_PATHS[site] || "";

      if (yn(env.FEATURE_BCT_SUNSET) && !isConcierge) {
        if (bctPath) {
          productUrl = bctPath + productUrl;
          site !== siteId && setOpenInNewTab(true);
        } else if (siteId !== "RH") {
          setOpenInNewTab(true);
        }
      } else if (yn(env.FEATURE_BCT_SUNSET) && isConcierge) {
        const reqContext: { cookies?: any } = getReqContext() ?? {};
        const conciergeCookie = new Cookies(reqContext.cookies);
        const savedBrand = conciergeCookie.get(SELECTED_BRAND_COOKIE);
        const [, setCookie] = useCookies([SELECTED_BRAND_COOKIE]);
        if (site !== savedBrand) {
          setCookie(SELECTED_BRAND_COOKIE, site, {
            expires: new Date(2147483647000)
          });
        }
      }
      return prefix + productUrl;
    }, [
      item,
      host,
      isStockedFilterActive,
      isRefinementFilterActive,
      totalNumRecs,
      Boolean(isSale || isSaleFilterEnabled),
      isConcierge,
      filterQueries,
      selectedSwatch,
      params?.site,
      searchPage,
      siteId
    ]);
    let saleUrl = "";
    const generatedSaleUrl = useMemo(() => {
      return (
        prefix +
        getUrl(
          item,
          host,
          isStockedFilterActive,
          isRefinementFilterActive,
          totalNumRecs,
          true,
          isConcierge,
          filterQueries,
          selectedSwatch,
          nextgenCookie === "true",
          inStockFlow,
          isNewURLFeatureEnabled,
          category
        )?.to
      );
    }, [
      item,
      host,
      isStockedFilterActive,
      isRefinementFilterActive,
      totalNumRecs,
      Boolean(isSale || isSaleFilterEnabled),
      isConcierge,
      filterQueries,
      selectedSwatch
    ]);
    if (
      item?.product?.percentSaleSkus !== 0 &&
      item?.product?.percentSaleSkus !== 100 &&
      !Boolean(isSale || isSaleFilterEnabled)
    ) {
      saleUrl = generatedSaleUrl;
    }

    const cols = useMemo(() => {
      return 12 / gridColumns;
    }, [gridColumns]);

    const productDetails: any = item?.product;
    // const swatchRows = item?.product?.swatchInfo?.swatchesToDisplay?.length;
    // let value = swatchRows !== undefined && swatchRows > 6 ? 6 : swatchRows;
    // const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    // const Wrapper = mdUp ? Fade : React.Fragment;
    const [
      getProductSwatchImage,
      {
        data: { productImage: productSwatchImage } = {} as Query,
        loading: productSwatchLoading
      }
    ] = useLazyQuery<Query>(queryProductImage, {
      onCompleted: data => {
        setIsClicked(true);
      },
      onError: data => {}
    });

    // const wrapperProps = useMemo(
    //   () => (mdUp ? { in: true, timeout: { enter: 1600, exit: 1600 } } : {}),
    //   [mdUp]
    // );
    const onSwatchClickHandler = useCallback(
      (e, index, swatch, disableProductCall?: boolean) => {
        if (isPGColorizationEnabled) {
          setSelectedSwatchIdx(index);
          setSelectedSwatch(swatch?.swatchId);
          if (isColorizable) {
            if (!disableProductCall) {
              setIsClicked(true);
              getProductSwatchImage({
                variables: {
                  productId: item?.product?.repositoryId,
                  swatchIds: swatch?.swatchId ? [swatch.swatchId] : [],
                  siteId,
                  locale
                }
              });
            }
          }
        }
        if (e) {
          e?.stopPropagation();
          e?.preventDefault();
        }
      },
      [
        getProductSwatchImage,
        isColorizable,
        isPGColorizationEnabled,
        item?.product?.repositoryId,
        locale,
        siteId
      ]
    );
    useEffect(() => {
      const swatchToDisplayId = item?.product?.displaySwatch;
      const swatchesToDisplay = item?.product?.swatchInfo?.swatchesToDisplay;
      if (
        swatchToDisplayId &&
        Array.isArray(swatchesToDisplay) &&
        swatchesToDisplay.length > 0
      ) {
        const swatchToDisplay = swatchesToDisplay.find(
          swatch => swatch.swatchId === swatchToDisplayId
        );
        if (swatchToDisplay) {
          onSwatchClickHandler(
            null,
            swatchesToDisplay.indexOf(swatchToDisplay),
            swatchToDisplay,
            true
          );
        }
      }
    }, []);

    const PRESET_MAP = useMemo(() => {
      return getPresetMap(
        isConciergeRHRImage,
        isRHRImage,
        item?.product?.pgImagePresetOverride
      );
    }, [isConciergeRHRImage, isRHRImage, item?.product?.pgImagePresetOverride]);

    const presetImage = useCallback(
      (rawURL: string | undefined, zoom?) => {
        try {
          if (!rawURL) return "";
          let preset = "";
          if (zoom) {
            preset = rawURL?.toUpperCase()?.includes("_RHR")
              ? PRESET_MAP.rhrThreeK
              : PRESET_MAP.threeK;
          } else if (xlUp) {
            preset = PRESET_MAP.xlUp[`col-${cols}`];
          } else if (lgUp) {
            preset = PRESET_MAP.lgUp[`col-${cols}`];
          } else if (mdUp || processEnvServer) {
            preset = PRESET_MAP.mdUp[`col-${cols}`];
          } else if (smUp) {
            preset = PRESET_MAP.smUp[`col-${cols}`];
          } else {
            preset = PRESET_MAP.xsUp[`col-${cols}`];
          }

          const url = new URL(`https:${rawURL}`);
          if (url?.search) {
            return `${rawURL}&${preset}`;
          } else {
            return `${rawURL}?${preset}`;
          }
        } catch (error) {
          return rawURL;
        }
      },
      [
        xlUp,
        lgUp,
        mdUp,
        smUp,
        PRESET_MAP.rhrThreeK,
        PRESET_MAP.threeK,
        PRESET_MAP.xlUp,
        PRESET_MAP.lgUp,
        PRESET_MAP.mdUp,
        PRESET_MAP.smUp,
        PRESET_MAP.xsUp,
        cols
      ]
    );

    const triggerAnalyticsEvent = () => {
      if (!isServer) {
        const itemList = [
          {
            item_name: item?.product?.displayName,
            item_id: item?.product?.repositoryId,
            item_category: productTitle,
            item_variant: null,
            quantity: null,
            price: null,
            item_list_name: window.location.href.includes("/search/")
              ? "search"
              : window.location.href.includes("products.jsp")
              ? "pg"
              : null
          }
        ];

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SELECT_ITEM.INT_TYPE,
            {
              itemList,
              item_list_name: true,
              id: item?.product?.repositoryId,
              displayName: item?.product?.displayName
            }
          )
        );
      }
    };

    const imagesArr = useMemo(() => {
      const product = item?.product;
      let images: string[] = [];
      if (product) {
        if (!product?.alternateImages?.length) {
          images.push(product?.imageUrl! || product?.altImageUrl!);
        } else if (product?.alternateImages?.length) {
          images = product?.alternateImages.map(data => data.imageUrl) || [];
        } else {
          images.push(product?.imageUrl! || product?.altImageUrl!);
        }
      }

      if (productSwatchImage?.imageUrl) {
        const { imageUrl } = productSwatchImage;
        const newArray = [...images];
        newArray[0] = imageUrl;
        images = newArray;
        setIsClicked(false);
      }
      return images.map(item => ({
        imageUrl: item
      })) as ProductAlternateImage[];
    }, [item?.product, productSwatchImage]);

    const imageAlternativeName = useMemo(() => {
      let name = "";
      if (Boolean(item?.product?.skuOptiondata)) {
        const optionData: any = item?.product?.skuOptiondata
          ? stringToObject(item?.product?.skuOptiondata)
          : {};

        if (optionData.hasOwnProperty("Finish")) {
          setIsSwatchFinish(true);
        } else {
          setIsSwatchFinish(false);
        }

        if (
          optionData.hasOwnProperty("Finish") &&
          optionData.hasOwnProperty("Color")
        ) {
          name = `${item?.product?.displayName} with ${optionData?.Color} fabric and ${optionData?.Finish} finish`;
        } else if (optionData.hasOwnProperty("Finish")) {
          name = `${item?.product?.displayName} in ${optionData?.Finish} finish`;
        } else if (optionData.hasOwnProperty("Color")) {
          name = `${item?.product?.displayName} in ${optionData?.Color}`;
        } else {
          name = item?.product?.displayName || "";
        }
      } else {
        name = item?.product?.displayName || "";
      }

      return name;
    }, [item, stringToObject]);

    const swatchAriaLabel = swatchName =>
      `${swatchName} ${isSwatchFinish ? "finish" : ""}`;

    const spinnerHeight = `calc(${
      productDetails?.imageContainerStyle?.height
    } + ${mdUp ? "16" : "8"}px)`;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isSelectedItem) {
        sleep(500).then(() => {
          ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        });
      }
    }, [isSelectedItem]);

    return (
      <div
        className={`w-full h-full flex flex-col relative ${
          gridColumns === 12 ? "item-center" : "unset"
        } `}
        ref={ref}
      >
        {productSwatchLoading && !processEnvServer ? (
          <div
            className={`flex justify-center h-full relative align-center my-1.5 sm:my-2 md:my-2.5`}
            style={{
              minHeight: spinnerHeight,
              height: spinnerHeight
            }}
          >
            <RHSpinner />
          </div>
        ) : !!imagesArr?.length ? (
          <ImageCarousel
            id={item?.product?.repositoryId}
            fallbackImage={(item?.product?.alternateImages || [])[0]?.imageUrl}
            setIsColorizable={setIsColorizable}
            colorizable={item?.product?.colorizable ?? false}
            slides={imagesArr ?? []}
            linkToPage={to}
            openInNewTab={openInNewTab}
            triggerAnalyticsEvent={triggerAnalyticsEvent}
            presetImage={presetImage}
            imageAlternativeName={imageAlternativeName}
            onProductClick={onProductClick}
            imageFlip={Boolean(item?.product.imageFlip)}
            isClicked={IsClicked}
            setIsClicked={setIsClicked}
            imageStyle={productDetails?.imageStyle || {}}
            imageContainerStyle={{
              justifyContent: "center",
              width: "100%", // container needs 100% width
              ...(productDetails?.imageContainerStyle
                ? {
                    aspectRatio:
                      productDetails?.imageContainerStyle?.aspectRatio ?? 1,
                    height: imagesArr[0]
                      ? productDetails?.imageContainerStyle?.maxHeight || "auto"
                      : 250
                  }
                : {})
            }}
          />
        ) : null}
        {!isColorizable &&
        selectedSwatchIdx > -1 &&
        isSwatchSelected &&
        item?.product?.swatchInfo?.swatchesToDisplay?.length ? (
          <div
            style={{ background: "rgba(0, 0, 0, 0.4)" }}
            className="w-full h-16 bg-black-40 flex items-center justify-center absolute top-0 z-40"
          >
            <Typography
              className={classNames(
                typographyStyles.rhBaseBody1,
                "!text-white"
              )}
            >
              {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON ||
                COLOR_PREVIEW_AVAILABLE_SOON}
            </Typography>
          </div>
        ) : null}
        <div className={`flex flex-col flex-wrap w-full h-full content-around`}>
          <div
            className={`flex flex-col h-full`}
            style={{ width: `${productDetails?.imageContainerStyle?.width}px` }}
          >
            <RHLink to={to} target={openInNewTab ? "_blank" : "_self"}>
              {/* !!NOTE :: on change of line height or padding please update calculateMaxHeightOfCaption with changed values  to get correct minHeight*/}
              <p
                className={classNames(
                  `text-[10px] sm:text-[13px] leading-[13.2px] sm:leading-5 my-0 text-black font-primary-thin pt-1.5 sm:pt-2.5 lg:pt-1.5 `,
                  {
                    "text-center": !!item?.product?.rhr,
                    "text-left": !item?.product?.rhr
                  }
                )}
                style={{
                  minHeight: `${productDetails?.captionMinHeight}px`,
                  width: `${productDetails?.imageContainerStyle?.width}px`
                }}
                dangerouslySetInnerHTML={{
                  __html: `${item?.product?.galleryDescription} `
                }}
              />

              <PD
                product={item?.product!}
                isStockedFilterActive={isStockedFilterActive}
                isRefinementFilterActive={isRefinementFilterActive}
                productSku={item?.sku!}
                isSale={isSale}
                host={host}
                totalNumRecs={totalNumRecs}
                isSaleFilterEnabled={isSaleFilterEnabled}
                gridColumns={gridColumns}
                saleUrl={saleUrl}
                inStockFlow={inStockFlow}
              />
            </RHLink>
            {item?.product?.swatchInfo?.swatchesToDisplay?.length ? (
              <div className="flex grow flex-col justify-end">
                <div
                  className={classNames(
                    `grid gap-x-0.5 grid-flow-col auto-cols-max mt-[20px] md:mt-4 lg:mt-5 !gap-[3px]`,
                    {
                      "place-content-center  mx-auto": item?.product?.rhr,
                      "place-content-start": !item?.product?.rhr
                    }
                  )}
                >
                  {item?.product?.swatchInfo?.swatchesToDisplay
                    ?.slice(0, 6)
                    ?.map((swatch, index) => {
                      const showUnderline =
                        (selectedSwatch === swatch?.swatchId ||
                          isSwatchSelected) &&
                        selectedSwatchIdx === index &&
                        isPGColorizationEnabled;

                      return (
                        // Heights are created as arbitrary values for now, Need to move to global config or change body style rem
                        <div
                          className="inline-flex flex-col"
                          key={`swatch-${swatch?.swatchId || index}`}
                        >
                          <button
                            className="aspect-[2/1] sm:aspect-[2.5/1] !h-3 lg:!h-4 xl:!h-5 inline-block !p-0"
                            aria-label={`${swatchAriaLabel(
                              swatch?.displayName
                            )}`}
                            onClick={e => {
                              setIsSwatchSelected(true);
                              onSwatchClickHandler(e, index, swatch);
                            }}
                          >
                            <RHImage
                              src={swatch.imageUrl}
                              alt={swatchAriaLabel(swatch?.displayName)}
                            />
                          </button>
                          <div
                            className={classNames([
                              `!h-[0.03rem] !border-black !mt-[3px] !py-0 !px-[1px]`,
                              showUnderline ? "" : "opacity-0"
                            ])}
                            style={{
                              border: "0.5px solid"
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

export const PC = memoize(ProductCard);
