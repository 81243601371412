import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";
// import { Fade } from "@mui/material";

import classNames from "classnames";

import RHPagination from "@RHCommerceDev/component-pagination";
import ItemsPerPage from "@RHCommerceDev/component-product-grid/ItemsPerPage";

import RHDivider from "@RHCommerceDev/component-rh-divider";

import { usePageContent } from "custom-providers/LocationProvider";
import { useImageSize } from "@RHCommerceDev/graphql-client/contexts/ImageSizeContext";

import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useFetchModel } from "hooks/useFetchModel";

import useMediaString from "@RHCommerceDev/hooks/useMediaString";
import useParams from "@RHCommerceDev/hooks/useParams";

import {
  processEnvServer as isServer,
  processEnvServer
} from "@RHCommerceDev/hooks/useSsrHooks";
import _chunk from "lodash/chunk";

import { useHistory, useLocation } from "react-router-dom";
import { RHRProductCardSkeleton } from "@RHCommerceDev/skeleton-rhr-product-list";
import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_VIEW,
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  ITEMS_PER_PAGE_PREFERENCE,
  PG_IMAGE_CONTAINER_DIMENSION,
  ZERO_RESULTS
} from "@RHCommerceDev/utils/constants";

import prasePGCropRules from "@RHCommerceDev/utils/prasePGCropRules";

import yn from "yn";

import { PC } from "./ProductCard";
import { showPGPaginationModule } from "@RHCommerceDev/utils/showPaginationModule";
import { useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";
import { useSipIdValue } from "@RHCommerceDev/hooks/atoms/useSIPID";
import { useInfiniteScroll } from "@RHCommerceDev/hooks/useInfiniteScroll";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";

function extractSkuOtions(option: string) {
  const facets = option?.split("|")?.reduce((acc, part) => {
    const [id, type, value] = part?.split("~~");
    acc[type?.toLowerCase()] = value;
    return acc;
  }, {});

  return facets;
}

export const getUrl = (
  item: SearchResultRecord | undefined,
  host: string = "",
  stocked: boolean,
  isRefinementFilterActive: boolean,
  totalNumRecs: number,
  isSale: boolean,
  isConcierge?: boolean,
  filterQueries?: string[],
  selectedSwatch?: string | null,
  isNextGen = false,
  inStockFlow = true,
  isNewURLFeatureEnabled: boolean = false,
  category: string = ""
) => {
  const saleParamString = isSale ? "true" : "";
  const formattedDisplayName = item?.product?.displayName
    ?.toLowerCase()
    .replace(/\s+/g, "-");

  const urlPath =
    isNewURLFeatureEnabled && category
      ? `/${category}/pdp/${formattedDisplayName}`
      : `/catalog/product/product.jsp/${item?.product?.repositoryId}`;

  const url = new URL(urlPath, host || "http://www.example.com");

  {
    isSale && url.searchParams.set("sale", saleParamString);
  }

  if (item?.product?.skuOptiondata?.length && isNextGen && !stocked) {
    const skuOptiondata = extractSkuOtions(item?.product?.skuOptiondata);
    Object.keys(skuOptiondata)?.map(key => {
      if (skuOptiondata[key] && key) {
        url.searchParams?.set(key, skuOptiondata[key]);
      }
    });
  }

  // SR-1909 : PDP-Page URL has sku details appended to product ID
  // if (
  //   item?.product?.repositoryId !== item?.sku?.fullSkuId &&
  //   (stocked || isRefinementFilterActive || totalNumRecs === 1) &&
  //   !isNextGen
  // ) {
  //   const skuOptiondataToObj = item?.product?.skuOptiondata
  //     ?.split(",")
  //     .reduce((acc, pair) => {
  //       const [key, value] = pair.split(":").map(item => item.trim());
  //       acc[key] = value;
  //       return acc;
  //     }, {});
  //   let color = skuOptiondataToObj?.["Color"] ?? null;
  //   totalNumRecs === 1 && !stocked
  //     ? url.searchParams.set("categoryId", `search`)
  //     : (url.searchParams.set("color", `${color}`),
  //       url.searchParams.set("categoryId", "search"));
  // }

  if (
    item?.product?.newPdpLayout &&
    // @ts-ignore
    !(!isConcierge && item?.product?.conciergeOnlyForNewPDP)
  ) {
    url.searchParams.set("version", "v2");
  }

  if (selectedSwatch) {
    url.searchParams.set("swatch", selectedSwatch);
  }
  url.searchParams.set("layout", `${item?.product?.pdpImageLayout}`);

  if (stocked) {
    if (inStockFlow) {
      url.searchParams.set("fullSkuId", item?.sku?.fullSkuId ?? "");
    } else {
      url.searchParams.set("inStock", "true");
    }
  }

  return {
    to: !host ? url.pathname + url.search : undefined
  };
};

export const getPriceUserType = (userType: string, price) => {
  switch (userType) {
    case "CONTRACT":
      return price?.contractPrice;
    case "TRADE":
      return price?.tradePrice;
    default:
      return price?.memberPrice;
  }
};

interface ProductGrid {
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  gridColumns: any;
  view: string;
  totalNumRecs: number;
  loadMoreData: () => any;
  productList: any[];
  noLazy?: boolean;
  host?: string;
  brand?: string;
  isSort?: boolean;
  productClickHandler?: (sipId: number) => void;
  disableFadeEffect?: boolean;
  isSale?: boolean;
  isSaleFilterEnabled?: boolean;
  productTitle?: string;
  pgCropRulesFromCg?: string;
  infiniteScrollEnabled?: boolean;
  isNextPageLoading?: boolean;
  recsPerPage: number;
  firstRecNum?: number;
  lastRecNum?: number;
  filterQueries?: string[];
  nextgenDriven?: boolean;
  inStockFlow?: boolean;
  activeTab?: any;
}
const ProductGrid: FC<ProductGrid> = ({
  gridColumns,
  isStockedFilterActive,
  isRefinementFilterActive,
  totalNumRecs,
  productList,
  loadMoreData,
  recsPerPage,
  noLazy = false,
  brand,
  isSort,
  productClickHandler,
  isSale,
  isSaleFilterEnabled,
  productTitle,
  view,
  infiniteScrollEnabled = true,
  isNextPageLoading = false,
  filterQueries,
  inStockFlow,
  activeTab
}) => {
  const env = useEnv();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("xl"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const FEATURE_INFINITE_SCROLL_REFACTOR = yn(
    env.FEATURE_INFINITE_SCROLL_REFACTOR
  );

  const { generateGridMap } = useImageSize();
  const [skeletonHeight, setSkeletonHeight] = useState(0);
  const [productGridColumns, setProductGridColumns] = useState<number>(4);
  const selectedProductRef = useRef<HTMLDivElement | null>(null);
  const skeletonRef = useRef<HTMLDivElement | null>(null);
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const { pathname } = useLocation();
  const isAemPage = !pathname?.includes(".jsp");
  const { pageContent } = !isAemPage
    ? usePageContent()
    : useFetchModel("/admin/products", false, false);
  const ItemsPerPageOptions = JSON.parse(
    pageContent?.items_per_page_options || "[]"
  );
  let mobile = false;
  const req = getReqContext();
  const userAgent = req && req?.headers["user-agent"];
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }
  const imageFlexBoxWidth = useMemo(() => {
    return gridColumns === 4
      ? lgUp
        ? "31.3%"
        : "30.3%"
      : gridColumns === 6
      ? lgUp
        ? "48.5%"
        : mdUp
        ? "47.8%"
        : "47.5%"
      : "100%";
  }, [gridColumns, mdUp, lgUp]);

  const flexboxContainerClasses = `inline-flex mb-8 md:mb-9 lg:mb-[60px] flex-wrap gap-x-4 sm:gap-x-8 md:gap-x-10 gap-y-7 sm:gap-y-12 md:gap-y-[60px] lg:gap-y-20 xl:gap-y-24 w-full`;

  useEffect(() => {
    if (infiniteScrollEnabled) return;
    window?.scrollTo(0, 0);
  }, [infiniteScrollEnabled]);

  const derivedProductList = useMemo(
    () =>
      noLazy ? productList?.slice(0, (12 / gridColumns) * 2) : productList,
    [noLazy, productList, gridColumns]
  );

  useEffect(() => {
    const sections: any = [];
    let counter = 0;
    derivedProductList?.forEach((item, i) => {
      if (i === 0) {
        sections[counter] = [i];
      }

      if (
        i > 0 &&
        item?.product?.anchor &&
        derivedProductList[i - 1]?.product?.anchor !== item?.product?.anchor &&
        !isSort
      ) {
        sections[counter].push(i - 1);
        counter++;
        sections[counter] = [i];
      }

      if (i === derivedProductList?.length - 1) {
        sections[counter].push(i);
      }
    });

    generateGridMap(sections, 12 / gridColumns);

    setTimeout(() => {
      if (productGridColumns !== gridColumns) {
        setProductGridColumns(gridColumns);
      }
    }, 500);
  }, [derivedProductList, gridColumns]);

  useEffect(() => {
    const newSkeletonHeight = skeletonRef?.current?.clientHeight || 40;
    if (skeletonHeight === newSkeletonHeight) return;
    setSkeletonHeight(newSkeletonHeight);
  }, [gridColumns]);

  const derivedProductListGroupedByAnchor = useMemo(
    () =>
      derivedProductList?.reduce(
        (acc = [[]], rec) => {
          const pgCropRules = prasePGCropRules(rec?.product?.pgCropRules);

          const record = {
            ...rec,
            product: { ...rec?.product, pgCropRules }
          };

          const arrayLastIndex = acc?.length - 1;
          const innerArrayLastIndex = acc?.[arrayLastIndex]?.length - 1;

          const arrayRecord = acc?.[arrayLastIndex];
          const innerArrayRecord = arrayRecord?.[innerArrayLastIndex];

          if (record?.product?.anchor) {
            const isTitleEqualToAnchor =
              productTitle?.toLowerCase() !==
              record?.product?.anchor?.toLowerCase();
            const isPrevAnchorNotEqualToNextAnchor =
              innerArrayRecord?.product?.anchor !== record?.product?.anchor;

            if (
              isTitleEqualToAnchor &&
              isPrevAnchorNotEqualToNextAnchor &&
              !isSort
            ) {
              acc?.push([record]);
            } else {
              arrayRecord?.push(record);
            }
          } else {
            if (Array.isArray(arrayRecord)) {
              arrayRecord?.push(record);
            } else {
              acc?.push([record]);
            }
          }

          return acc;
        },
        [[]]
      ),
    [derivedProductList, isSort, productTitle]
  );
  let mediaString;
  if (processEnvServer) {
    mobile ? (mediaString = "xs") : (mediaString = "xl");
  } else {
    mediaString = useMediaString();
  }

  // Utility function to calculate max height based on max string length and width
  const calculateMaxHeightOfCaption = (items: string[], width) => {
    // Get the string with the maximum length
    const longestString = _.maxBy(items, item => item?.length) || "";
    const charsPerLine = smUp ? Math.floor(width / 5) : Math.floor(width / 4);
    // Calculate number of lines based on the longest string
    const lines = Math.ceil(longestString.length / charsPerLine);
    //lineHeight and padding as taken from product card classname
    const lineHeight = smUp ? 20 : 13.2;
    const padding = lgUp ? 6 : smUp ? 10 : 6;

    const calculatedHeight = lines * lineHeight + padding;

    return calculatedHeight;
  };

  const history = useHistory();
  const sipId = useSipIdValue();
  const selectedItemId = useMemo(() => {
    if (history?.action === "POP" || history?.action === "REPLACE") {
      return sipId;
    }
  }, [history?.action, sipId]);

  const parsedDerivedProductList = useMemo(() => {
    return derivedProductListGroupedByAnchor?.flatMap(derivedProductList =>
      _chunk(derivedProductList, 12 / gridColumns)?.flatMap(
        (groupedDerivedProduct: any) => {
          const imgContainerHeight = groupedDerivedProduct?.map(
            derivedProduct => derivedProduct?.product?.pgCropRules?.height
          );

          const areAllRhr = groupedDerivedProduct?.every(
            derivedProduct => derivedProduct?.product?.rhr
          );

          const imagCaptionsArr = groupedDerivedProduct?.map(
            derivedProduct => derivedProduct?.product?.galleryDescription
          );

          const maxImageContainerHeight = Math?.max(...imgContainerHeight);
          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;
          const imageContainerHeight = `${
            (maxImageContainerHeight / 100) * MAX_IMG_CONTAINER_HEIGHT
          }px`;
          const aspectRatio =
            view === "vertical"
              ? IMAGE_ASPECT_RATIO?.verticalProductTile
              : IMAGE_ASPECT_RATIO?.horizontalProductTile;
          return groupedDerivedProduct?.map(derivedProduct => {
            const imgHeight = `${
              (derivedProduct?.product?.pgCropRules?.height / 100) *
              MAX_IMG_CONTAINER_HEIGHT
            }px`;

            const captionMinHeight = calculateMaxHeightOfCaption(
              imagCaptionsArr,
              aspectRatio * MAX_IMG_CONTAINER_HEIGHT
            );

            return {
              ...derivedProduct,
              product: {
                ...derivedProduct?.product,
                captionMinHeight: captionMinHeight,
                imageStyle: {
                  objectFit: "contain",
                  alignSelf: "flex-end",
                  maxWidth: "100%",
                  maxHeight: derivedProduct?.product?.rhr
                    ? imgHeight
                    : MAX_IMG_CONTAINER_HEIGHT,
                  width: "auto",
                  height: "auto",
                  transitionProperty: "opacity"
                },
                imageContainerStyle: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  aspectRatio: derivedProduct?.product?.rhr
                    ? null
                    : aspectRatio?.toString(),
                  height: areAllRhr
                    ? imageContainerHeight
                    : `${MAX_IMG_CONTAINER_HEIGHT}px`,
                  maxHeight: areAllRhr
                    ? imageContainerHeight
                    : MAX_IMG_CONTAINER_HEIGHT,
                  width: aspectRatio * MAX_IMG_CONTAINER_HEIGHT
                }
              }
            };
          });
        }
      )
    );
  }, [derivedProductListGroupedByAnchor, gridColumns, mediaString, view]);

  const skeletonUi = useMemo(
    () =>
      Array.from(new Array(12 / gridColumns)).map((item, index) => (
        <RHRProductCardSkeleton key={`${index}`} width={imageFlexBoxWidth} />
      )),
    [gridColumns]
  );

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(ITEMS_PER_PAGE_PREFERENCE)
    : undefined;

  const params = useParams({
    no: "0",
    maxnrpp: storedItemsPerPagePreference ?? "24",
    loadAll: ""
  });

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      if (target.isIntersecting && productList.length < totalNumRecs) {
        loadMoreData();
      }
    },
    [loadMoreData, productList.length, totalNumRecs]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "50px",
      threshold: 0.5
    });

    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [handleObserver]);

  const parsedDerivedProductListWithLoader = useMemo(() => {
    if (!parsedDerivedProductList) return [];
    const listWithLoader = [...parsedDerivedProductList];
    const position = listWithLoader.length - 12;
    if (position >= 0 && listWithLoader[position]) {
      listWithLoader[position].loader = true;
    }
    return listWithLoader;
  }, [parsedDerivedProductList]);

  const hasMore = useMemo(
    () => (noLazy ? false : productList?.length < totalNumRecs),
    [noLazy, productList?.length, totalNumRecs]
  );

  const { ref: infiniteScrollSkeletonRef } = useInfiniteScroll({
    callback: noLazy ? () => {} : loadMoreData,
    hasMore,
    loading: isNextPageLoading
  });

  return (
    <div
      id="component-product-grid"
      className="relative"
      style={{
        ...(infiniteScrollEnabled || isNextPageLoading
          ? {
              paddingBottom:
                productList?.length < totalNumRecs ? skeletonHeight : 0
            }
          : {})
      }}
    >
      {infiniteScrollEnabled ? (
        <div>
          {FEATURE_INFINITE_SCROLL_REFACTOR ? (
            <div className={flexboxContainerClasses}>
              {parsedDerivedProductListWithLoader?.map((item, index) => {
                return (
                  <>
                    {productTitle?.toLowerCase() !==
                    item?.product?.anchor?.toLowerCase() ? (
                      <>
                        {item?.product?.anchor &&
                          parsedDerivedProductList[index - 1]?.product
                            ?.anchor !== item?.product?.anchor &&
                          !isSort && (
                            <div className="col-span-full  w-full">
                              {index > 0 && (
                                <RHDivider className="!mb-[60px]" />
                              )}{" "}
                              <div className="uppercase font-primary-ultra-thin !text-[24px]">
                                {item?.product?.anchor}
                              </div>
                            </div>
                          )}
                      </>
                    ) : null}
                    <div
                      key={`innerGrid_item_${index}`}
                      id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
                      className="flex justify-center productVisible mb-3"
                      style={{
                        width: imageFlexBoxWidth
                      }}
                      ref={index === selectedItemId ? selectedProductRef : null}
                    >
                      <PC
                        item={item}
                        isSale={isSale}
                        isSaleFilterEnabled={isSaleFilterEnabled}
                        totalNumRecs={totalNumRecs}
                        isStockedFilterActive={isStockedFilterActive}
                        isRefinementFilterActive={isRefinementFilterActive}
                        gridColumns={productGridColumns}
                        filterQueries={filterQueries}
                        pageContent={pageContent}
                        productTitle={productTitle}
                        onProductClick={() => {
                          productClickHandler?.(index);
                        }}
                        inStockFlow={inStockFlow}
                        isSelectedItem={index === selectedItemId}
                      />
                      {item.loader && <div ref={loaderRef} key="loader"></div>}
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <>
              <div className={flexboxContainerClasses}>
                {parsedDerivedProductList?.map((item, index) => {
                  return (
                    <>
                      {productTitle?.toLowerCase() !==
                      item?.product?.anchor?.toLowerCase() ? (
                        <>
                          {item?.product?.anchor &&
                            parsedDerivedProductList[index - 1]?.product
                              ?.anchor !== item?.product?.anchor &&
                            !isSort && (
                              <div className="col-span-full w-full">
                                {index > 0 && (
                                  <RHDivider className="!mb-[60px]" />
                                )}{" "}
                                <div className="uppercase font-primary-ultra-thin !text-[24px]">
                                  {item?.product?.anchor}
                                </div>
                              </div>
                            )}
                        </>
                      ) : null}
                      <div
                        key={`innerGrid_item_${index}`}
                        id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
                        className="flex justify-center productVisible mb-3"
                        style={{
                          width: imageFlexBoxWidth
                        }}
                        ref={
                          index === selectedItemId ? selectedProductRef : null
                        }
                      >
                        <PC
                          item={item}
                          isSale={isSale}
                          isSaleFilterEnabled={isSaleFilterEnabled}
                          totalNumRecs={totalNumRecs}
                          isStockedFilterActive={isStockedFilterActive}
                          isRefinementFilterActive={isRefinementFilterActive}
                          gridColumns={productGridColumns}
                          filterQueries={filterQueries}
                          pageContent={pageContent}
                          productTitle={productTitle}
                          onProductClick={() => {
                            productClickHandler?.(index);
                          }}
                          inStockFlow={inStockFlow}
                          isSelectedItem={index === selectedItemId}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              {hasMore && (
                <div
                  ref={infiniteScrollSkeletonRef}
                  className={classNames(flexboxContainerClasses)}
                >
                  {skeletonUi}
                </div>
              )}
            </>
          )}

          {FEATURE_INFINITE_SCROLL_REFACTOR && isNextPageLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "60px"
              }}
            >
              {skeletonUi}
            </div>
          )}
        </div>
      ) : (
        <div className={classNames(flexboxContainerClasses)}>
          {parsedDerivedProductList?.map((item, index) => (
            <>
              {productTitle?.toLowerCase() !==
              item?.product?.anchor?.toLowerCase() ? (
                <>
                  {item?.product?.anchor &&
                    parsedDerivedProductList[index - 1]?.product?.anchor !==
                      item?.product?.anchor &&
                    !isSort && (
                      <div className="col-span-full w-full">
                        {index > 0 && <RHDivider className="!mb-[60px]" />}{" "}
                        <div className="uppercase font-primary-ultra-thin !text-[24px]">
                          {item?.product?.anchor}
                        </div>
                      </div>
                    )}
                </>
              ) : null}
              <div
                key={`innerGrid_item_${index}`}
                id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
                className={classNames(
                  !processEnvServer
                    ? "flex justify-center productVisible mb-3 w-full"
                    : ""
                )}
                style={{
                  width: imageFlexBoxWidth
                }}
              >
                <PC
                  item={item}
                  isSale={isSale}
                  isSaleFilterEnabled={isSaleFilterEnabled}
                  totalNumRecs={totalNumRecs}
                  isStockedFilterActive={isStockedFilterActive}
                  isRefinementFilterActive={isRefinementFilterActive}
                  gridColumns={gridColumns}
                  filterQueries={filterQueries}
                  pageContent={pageContent}
                  productTitle={productTitle}
                  onProductClick={() => {
                    productClickHandler?.(index);
                  }}
                  inStockFlow={inStockFlow}
                  isSelectedItem={index === selectedItemId}
                />
              </div>
            </>
          ))}
        </div>
      )}
      {showPGPaginationModule(isNextPageLoading, totalNumRecs) ? (
        <>
          {ItemsPerPageOptions.includes(+Number(params.maxnrpp)) &&
            params?.loadAll !== "true" && (
              <RHPagination
                recsPerPage={recsPerPage}
                lastRecNum={productList?.length - 1}
                totalNumRecs={totalNumRecs}
                loadMoreData={loadMoreData}
                activeTab={activeTab}
              />
            )}
          <ItemsPerPage
            recsPerPage={recsPerPage}
            lastRecNum={productList?.length - 1}
            totalNumRecs={totalNumRecs}
            loadMoreData={loadMoreData}
          />
        </>
      ) : null}
    </div>
  );
};

ProductGrid.defaultProps = {
  productList: [],
  isStockedFilterActive: false,
  isRefinementFilterActive: false,
  gridColumns: DEFAULT_GRID_COLUMNS,
  view: DEFAULT_VIEW,
  totalNumRecs: ZERO_RESULTS
};

export default ProductGrid;
