import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import useTypographyStyles from "hooks/useTypographyStyles";
import memoize from "utils/memoize";
import { formatCurrency } from "utils/currencyHelpers";
import countriesConfig from "resources/countries-config.json";

interface PriceProps extends TypographyProps {
  children?: number | string | null;
  /** @deprecated use currency */
  currencySymbol?: string | null;
  currencyLabel?: string | null;
  currency?: Maybe<string>;
  showCents?: boolean;
  strikethrough?: boolean;
  id?: string;
}

export const Price: FC<PriceProps> = ({
  children,
  currencySymbol = "",
  currencyLabel = "",
  currency,
  showCents = false,
  strikethrough = false,
  className = "",
  style,
  variant,
  id,
  ...props
}) => {
  const classes = useTypographyStyles({
    keys: ["priceRange", "rhBaseCaption"]
  });

  const currencyCode = useMemo(
    () =>
      currencySymbol &&
      countriesConfig.currencySymbolToCurrencyCodeMapper[currencySymbol],
    [currencySymbol]
  );

  const currencyWithSymbol: string | null | undefined | false =
    (currency &&
      !isNaN(Number(children)) &&
      formatCurrency(Number(children), currency)) ||
    (currencyCode === "EUR"
      ? `${currencyLabel ?? ""}${children
          ?.toString()
          ?.trim()} ${currencySymbol}`
      : `${currencyLabel ?? ""}${currencySymbol}${children}`);

  const currencySymbolFromCurrency = useMemo(
    () => currencyWithSymbol?.charAt(currencyWithSymbol?.length - 1),
    [currencyWithSymbol]
  );

  return (
    children !== null &&
    children !== undefined && (
      <Typography
        id={id || "price"}
        {...props}
        className={classNames(
          classes.rhBaseCaption,
          classes.priceRange,
          className
        )}
        style={{
          textDecoration: strikethrough ? "line-through" : "",
          fontWeight: "initial",
          ...style
        }}
      >
        {currencyWithSymbol ? currencyWithSymbol : null}
        {
          countriesConfig.currencySymbolToCurrencyCodeMapper[
            currencySymbolFromCurrency
          ] === "EUR"
            ? null
            : showCents && currency !== "EUR"
            ? ".00"
            : "" /** TODO make this better */
        }
      </Typography>
    )
  );
};

export default memoize(Price);
